import axios from "axios";
import { EChartsData, PreReqData, PreReqNode } from "./PreRequisitesTypes";

const getCoursePreRequisites = async (
  courseCode: string,
  courseNum: string
) => {
  const rawData = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/api/research/prerequisites/${courseCode}/${courseNum}`
  );

  const courseData: PreReqData = {
    name: rawData.data.code,
    prereqs: rawData.data.preq,
    coreqs: rawData.data.creq,
    prereqDesc: rawData.data.prer,
  };
  return courseData;
};

export const generatePreReqData = async (
  courseCode: string,
  courseNum: string
): Promise<PreReqNode> => {
  const courseData = await getCoursePreRequisites(courseCode, courseNum);
  if (courseData.prereqs === undefined) {
    return {
      name: `${courseCode} ${courseNum}`,
      prereqs: [],
      coreqs: [],
      prereqDesc: "No data found",
      prereqNodes: [],
    };
  }
  const prereqNodes: PreReqNode[] = [];
  for (const prereq of courseData.prereqs) {
    const [prereqCode, prereqNum] = prereq.split(" ");

    if (prereqCode === courseCode) {
      const prereqData = await generatePreReqData(prereqCode, prereqNum);
      prereqNodes.push(prereqData);
    }
  }

  return {
    ...courseData,
    prereqNodes,
  };
};

export const convertToEChartsTreeData = (node: PreReqNode): EChartsData => {
  return {
    name: node.name,
    children: node.prereqNodes.map(convertToEChartsTreeData),
    tooltip: {
      formatter: node.prereqDesc || node.name,
    },
    itemStyle: {
      color: "#00CCFF",
    },
  };
};
