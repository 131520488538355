import React, { createContext, useState } from "react";

const EditLayoutContext = createContext<{
  editLayout: boolean;
  setEditLayout: (newEditLayoutState: boolean) => void;
  resetKey: boolean;
  setResetKey: (newResetKey: boolean) => void;
}>({
  editLayout: false,
  setEditLayout: () => {},
  resetKey: false,
  setResetKey: () => {},
});

export const EditLayoutProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [editLayout, setEditLayout] = useState<boolean>(false);
  const [resetKey, setResetKey] = useState<boolean>(false);

  const contextValue = {
    editLayout,
    setEditLayout,
    resetKey,
    setResetKey,
  };

  return (
    <EditLayoutContext.Provider value={contextValue}>
      {children}
    </EditLayoutContext.Provider>
  );
};

export default EditLayoutContext;
