import { Card, CardMedia, useTheme, useMediaQuery } from "@mui/material"
import { heroImage } from "./LandingPageContent";

const HeroImage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Card
        sx={{
          backgroundColor: "#ddd",
          height: "100%",
          display: isMobile ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "9vh",
          marginRight: "2vw",
        }}
      >
        <CardMedia
          component="img"
          alt={heroImage.alt}
          height="50%"
          title={heroImage.title}
          src={heroImage.source}
        />
      </Card>
    )
}

export default HeroImage;