import React, { ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";
import { PanelColor } from "../Theme";

interface PanelProps extends BoxProps {
  children: ReactNode;
}

const Panel: React.FC<PanelProps> = ({ children }) => (
  <Box
    sx={{
      padding: "1rem",
      backgroundColor: PanelColor,
      borderRadius: "8px",
      marginBottom: "0.75rem",
      textAlign: "Left",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.1), 0px 1px 3px 0px rgba(0,0,0,0.08);",
    }}
  >
    {children}
  </Box>
);

export default Panel;
