import React, { Fragment, ReactNode } from "react";
import { Box, Button, Card, CardProps, Typography } from "@mui/material";
import { OpenInFull } from "@mui/icons-material";
import {
  componentMap,
  ResearchComponents,
} from "src/containers/ResearchContainer/ResearchTypes";
import FocusedResearchCard from "./FocusedResearchCard";

interface ResearchCardProps extends CardProps {
  children: ReactNode;
  title: string;
  isFocused: boolean;
  expandable: boolean | false;
  setFocusComponent: (data: ResearchComponents | null) => void;
  topbarChildren?: ReactNode;
}

/*
  ResearchCard component is a Card that contains a title, children, and optional topbarChildren.
  The topbarChildren are displayed at the top right of the card and are used to add additional functionality.
  Use these to add filters or buttons to the card that you don't want taking space in the main body of the card.
  If expandable is true, a button is displayed to expand the card to full screen.
  When the button is clicked, the setFocusComponent function is called with the corresponding ResearchComponent.
  This can be used to conditionall render a FocusResearchComponent which is a full screen version of the ResearchComponent.
*/
const ResearchCard: React.FC<ResearchCardProps> = ({
  children,
  title,
  expandable,
  setFocusComponent,
  topbarChildren,
  isFocused,
}) => (
  <Fragment>
    {!isFocused ? (
      <Card
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "2.5rem auto",
            gridGap: "10px",
            padding: "10px",
            height: "calc(100% - 20px)",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto auto",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{title}</Typography>
            {topbarChildren && (
              <Box sx={{ display: "grid", justifyContent: "center" }}>
                {topbarChildren}
              </Box>
            )}
            {expandable && setFocusComponent && (
              <Button
                onClick={() => {
                  setFocusComponent(componentMap[title]);
                }}
              >
                <OpenInFull color="primary" />
              </Button>
            )}
          </Box>
          <div style={{ overflow: "auto" }}>{children}</div>
        </Box>
      </Card>
    ) : (
      <FocusedResearchCard title={title} setFocusComponent={setFocusComponent}>
        {children}
      </FocusedResearchCard>
    )}
  </Fragment>
);

export default ResearchCard;
