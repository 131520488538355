import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Link,
  MobileStepper,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getProfData } from "./RMPInsightsHelper";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { RMPData } from "src/containers/CalendarContainer/SectionInfoPanel/helpers/RateMyProfHelpers";
import RMPSkeleton from "./RMPSkeleton";
import { theme } from "src/Theme";

interface RMPInsightsProps {
  selectedCourse: string | null;
}

function RMPInsights({ selectedCourse }: RMPInsightsProps) {
  const [profData, setProfData] = useState<RMPData[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const isMediumDevice = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedCourse) {
      getProfData(selectedCourse).then((data) => {
        setProfData(data);
      });
    }
  }, [selectedCourse]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <React.Fragment>
      {selectedCourse ? (
        <Card sx={{ width: "100%", height: "100%", overflowY: "auto" }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: "10%",
              justifyContent: "center",
              bgcolor: "background.default",
            }}
          >
            <Link
              href={profData[activeStep]?.link}
              target="_blank"
              variant="h5"
              align="center"
              color="primary"
            >
              {profData[activeStep]?.name}
            </Link>
          </Paper>
          <CardContent>
            <Stack
              direction={isMediumDevice ? "column" : "row"}
              spacing="2"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Box
                sx={{
                  width: isMediumDevice ? "80%" : "40%",
                  pl: "5%",
                  pr: "5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Rating:</Typography>
                <Chip
                  variant="outlined"
                  color="primary"
                  sx={{ marginLeft: "25px" }}
                  label={`${
                    profData[activeStep] ? profData[activeStep]?.rating : "??"
                  } / 5`}
                />
              </Box>
              <Box
                sx={{
                  width: isMediumDevice ? "80%" : "40%",
                  pl: "5%",
                  pr: "5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Number of ratings:</Typography>
                <Chip
                  variant="outlined"
                  color="primary"
                  sx={{ ml: "25px", mt: isMediumDevice ? "3%" : "0" }}
                  label={`${
                    profData[activeStep]
                      ? profData[activeStep]?.numRatings
                      : "??"
                  }`}
                />
              </Box>
            </Stack>
            <Stack
              direction={isMediumDevice ? "column" : "row"}
              spacing="2"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Box
                sx={{
                  width: isMediumDevice ? "80%" : "40%",
                  pl: "5%",
                  pr: "5%",
                  pt: "3%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Average Difficulty:</Typography>
                <Chip
                  variant="outlined"
                  color="primary"
                  sx={{ marginLeft: "25px" }}
                  label={`${
                    profData[activeStep]
                      ? profData[activeStep]?.avgDifficulty
                      : "??"
                  } / 5`}
                />
              </Box>
              <Box
                sx={{
                  width: isMediumDevice ? "80%" : "40%",
                  pl: "5%",
                  pr: "5%",
                  pt: "3%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" noWrap={false}>
                  Would Take Again:
                </Typography>
                <Chip
                  variant="outlined"
                  color="primary"
                  sx={{ marginLeft: "25px" }}
                  label={`${
                    profData[activeStep]
                      ? Math.round(
                          profData[activeStep]?.wouldTakeAgainPercent * 100
                        ) / 100
                      : "??"
                  }${profData[activeStep] ? "%" : ""}`}
                />
              </Box>
            </Stack>
            <MobileStepper
              steps={profData.length}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={
                    activeStep === profData.length - 1 || profData.length === 0
                  }
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0 || profData.length == 0}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </CardContent>
        </Card>
      ) : (
        <RMPSkeleton />
      )}
    </React.Fragment>
  );
}
export default RMPInsights;
