import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { IReview } from "../ReviewTypes";
import { submitReview, validateReview } from "./NewReviewFormHelper";
import { ALL_COURSES } from "src/assets/courselist";
import { useFirebaseAuth } from "src/containers/AuthPages/Firebase";

const NewReviewForm = () => {
  const [comments, setComments] = useState<string>("");
  const [courseCode, setCourseCode] = useState<string>("");
  const [professor, setProfessor] = useState<string>("");
  const [rating, setRating] = useState<string>("");
  const [term, setTerm] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [submitButtonPressed, setSubmitButtonPressed] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const loggedInUser = useFirebaseAuth().userProfile;

  const SESSIONS_LIST = ["Winter 1", "Winter 2", "Summer 1", "Summer 2"];
  const YEAR_LIST = ["2024", "2023", "2022", "2021", "2020"];
  const handleOnSubmit = () => {
    try {
      // Need to validate here before accessing loggedInUser.UserId
      if (loggedInUser == null || loggedInUser.UserId == null) {
        throw new Error("You must be logged in to leave a review.");
      }

      setSubmitButtonPressed(true);
      let review: IReview = {
        reviewId: crypto.randomUUID(),
        userId: loggedInUser.UserId,
        userName: loggedInUser.userName,
        userProfilePicture: loggedInUser.picture || "",
        comments: comments,
        courseCode: courseCode,
        professor: professor,
        rating: rating,
        term: term,
        year: year,
        datePosted: new Date().toISOString(),
        upvotes: 0,
        downvotes: 0,
      };
      validateReview(review);
      setErrorMessage("");

      submitReview(review);
      setSuccessMessage("Submission successful!  Please refresh the page.");
    } catch (err) {
      if (err instanceof Error) setErrorMessage(err.message);
    }
  };
  const handleCourseChange = (event: any, newValue: any) => {
    setCourseCode(newValue || event.target.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
      <Stack direction="row">
        <Autocomplete
          disablePortal
          id="course-code-autocomplete"
          options={ALL_COURSES}
          sx={{ width: "35%", marginBottom: "20px" }}
          value={courseCode}
          onChange={handleCourseChange}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={submitButtonPressed && courseCode.length === 0}
              variant="filled"
              label="Course Code"
            />
          )}
        />
        <TextField
          sx={{ marginBottom: "20px", marginLeft: "2%" }}
          required
          error={submitButtonPressed && rating.length == 0}
          label="Rating"
          type="number"
          value={rating}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setRating(event.target.value.toString());
          }}
          id="standard-basic"
          variant="filled"
        />
        <TextField
          sx={{ marginBottom: "20px", marginLeft: "2%" }}
          required
          error={submitButtonPressed && professor.length == 0}
          label="Professor"
          value={professor}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProfessor(event.target.value);
          }}
          id="standard-basic"
          variant="filled"
        />
      </Stack>
      <TextField
        sx={{ marginBottom: "20px" }}
        required
        error={submitButtonPressed && comments.length == 0}
        multiline
        minRows={3}
        maxRows={5}
        label="Comments"
        value={comments}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setComments(event.target.value);
        }}
        id="standard-basic"
        variant="filled"
      />

      <Stack direction="row">
        <FormControl required fullWidth sx={{ marginBottom: "20px" }}>
          <InputLabel id="term-label">Term</InputLabel>
          <Select
            required
            labelId="term-label"
            id="term"
            value={term}
            label="Term"
            error={submitButtonPressed && term.length == 0}
            variant="filled"
            onChange={(event: any) => {
              setTerm(event.target.value);
            }}
          >
            {SESSIONS_LIST.map((session) => (
              <MenuItem key={session} value={session}>
                {session}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          required
          fullWidth
          sx={{ marginBottom: "20px", marginLeft: "5%" }}
        >
          <InputLabel id="year-label">Year</InputLabel>
          <Select
            required
            labelId="year-label"
            id="year"
            value={year}
            label="Year"
            error={submitButtonPressed && year.length == 0}
            variant="filled"
            onChange={(event: any) => {
              setYear(event.target.value);
            }}
          >
            {YEAR_LIST.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <div className="FooterContainer">
        <Button
          sx={{ maxWidth: "70px", height: "35px" }}
          variant="contained"
          onClick={() => {
            handleOnSubmit();
          }}
          disabled={successMessage.length > 0}
        >
          Submit
        </Button>
        {errorMessage.length >= 0 && (
          <p className="ErrorMessage">{errorMessage}</p>
        )}
        {successMessage.length >= 0 && (
          <p className="SuccessMessage">{successMessage}</p>
        )}
      </div>
    </Box>
  );
};

export default NewReviewForm;
