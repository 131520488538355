import React from 'react';
import ReactECharts from 'echarts-for-react';
import { convertToEChartsTreeData } from './PreRequisitesHelpers';
import { PreReqNode } from './PreRequisitesTypes';

const PreReqTree: React.FC<{ data: PreReqNode }> = ({ data }) => {
  const options = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove'
    },
    series: [
      {
        type: 'tree',
        data: [convertToEChartsTreeData(data)],
        top: '5%',
        left: '10%',
        bottom: '5%',
        right: '10%',
        symbol: 'circle',
        symbolSize: 10,
        label: {
          position: 'left',
          verticalAlign: 'middle',
          align: 'right',
          fontSize: 12,
          formatter: '{b}'
        },
        leaves: {
          label: {
            position: 'right',
            verticalAlign: 'middle',
            align: 'left'
          }
        },
        expandAndCollapse: true,
        initialTreeDepth: 3,
        animationDuration: 750,
        lineStyle: {
          color: '#ccc',
          width: 1.5
        }
      }
    ]
  };

  return <ReactECharts option={options} style={{ height: '100%', width: '100%' }} />;
};

export default PreReqTree;
