import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Skeleton,
} from "@mui/material";

/*
  Placeholder to display when selected course is null
*/
function ReviewsSkeleton() {
  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Card variant="outlined">
          <CardHeader
            avatar={<Skeleton variant="circular" width={40} height={40} />}
            title={
              <Container
                sx={{
                  display: "flex",
                  gap: "2%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="text" width={200} height={40} />
              </Container>
            }
          />
          <CardContent>
            <Skeleton variant="text" width="100%" height={40} />
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="100%" height={20} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default ReviewsSkeleton;
