import { ISectionData } from "src/App.types";
import { IDirectionSection, ISectionPair } from "../Direction.types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Fragment, useState } from "react";
import SectionsTopBar from "./SectionsTopBar";

interface IProps {
  setSectionPairs: (data: ISectionPair[] | null) => void;
  sections: ISectionData[];
  selectedSections: IDirectionSection[];
  setSelectedSections: (data: IDirectionSection[]) => void;
  setActiveSectionPair: (data: ISectionPair | null) => void;
  setExpandedDirectionsCard: (data: string | null) => void;
}

const MobileSectionsTopBar = ({
  setSectionPairs,
  selectedSections,
  setSelectedSections,
  setActiveSectionPair,
  setExpandedDirectionsCard,
  sections,
}: IProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Fragment>
      <Button
        variant="contained"
        onClick={() => {
          handleClickOpen();
        }}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginLeft: "3%",
          marginRight: "3%",
          marginTop: "3%",
        }}
      >
        Filters
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle id="dialog-title">{"Filter Courses"}</DialogTitle>
        <DialogContent>
          <SectionsTopBar
            setSectionPairs={setSectionPairs}
            selectedSections={selectedSections}
            setSelectedSections={setSelectedSections}
            sections={sections}
            dialogVariant={true}
            setActiveSectionPair={setActiveSectionPair}
            setExpandedDirectionsCard={setExpandedDirectionsCard}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default MobileSectionsTopBar;
