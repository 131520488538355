import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { IUserProfile } from "@shared/UserTypes";
import React, { useState } from "react";
import { saveUserProfile, validateUserProfile } from "./ProfileHelper";

interface IEditProfileModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  userProfile: IUserProfile;
  setUserProfile: (user: IUserProfile) => void;
  setProfilePageData: (user: IUserProfile) => void;
}

const EditProfileModal = ({
  showModal,
  setShowModal,
  userProfile,
  setUserProfile,
  setProfilePageData,
}: IEditProfileModalProps) => {
  const [newUserData, setNewUserData] = useState<IUserProfile>(userProfile);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleClose = () => {
    setShowModal(false);
    setNewUserData(userProfile);
    setErrorMessage("");
  };

  const updateUserData = (field: string, value: string) => {
    let tempUserData = { ...newUserData, [field]: value };
    setNewUserData(tempUserData);
  };

  const handleUpdateYear = (newYear: string) => {
    let year: number = Number(newYear);
    if (!isNaN(year)) {
      let tempUserData = { ...newUserData, year: year };
      setNewUserData(tempUserData);
    }
  };

  const handleSave = async () => {
    try {
      validateUserProfile(newUserData);

      await saveUserProfile(newUserData);

      setUserProfile(newUserData);
      setProfilePageData(newUserData);
      setErrorMessage("");
      setShowModal(false);
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        setErrorMessage(err.message);
      } else {
        setErrorMessage("failed to save profile data");
        console.error(err);
      }
    }
  };

  return (
    <Modal open={showModal} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxWidth: "80vw",
          height: "60vh",
          overflowY: "auto",
          bgcolor: "white",
          border: "2px solid #000",
          boxShadow: 24,
          padding: 4,
        }}
      >
        <Typography variant="h3">Edit Profile</Typography>
        <TextField
          sx={{ marginTop: "1rem", marginBottom: "1rem", width: "100%" }}
          size="small"
          label="Username"
          required
          value={newUserData.userName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateUserData("userName", event.target.value);
          }}
          id="userNameInput"
          variant="filled"
        />
        <TextField
          sx={{ marginBottom: "1rem", width: "100%" }}
          size="small"
          label="Full Name"
          value={newUserData.fullName ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateUserData("fullName", event.target.value);
          }}
          id="fullNameInput"
          variant="filled"
        />
        <TextField
          sx={{ marginBottom: "1rem", width: "100%" }}
          size="small"
          label="About"
          value={newUserData.about ?? ""}
          multiline
          minRows={3}
          maxRows={5}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateUserData("about", event.target.value);
          }}
          id="aboutInput"
          variant="filled"
        />
        <TextField
          sx={{ marginBottom: "1rem", width: "100%" }}
          size="small"
          label="Photo URL"
          value={newUserData.picture ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateUserData("picture", event.target.value);
          }}
          helperText="Enter a valid image url"
          id="pictureInput"
          variant="filled"
        />
        <TextField
          sx={{ marginBottom: "1rem", width: "100%" }}
          size="small"
          label="Major"
          value={newUserData.major ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateUserData("major", event.target.value);
          }}
          id="majorInput"
          variant="filled"
        />
        <TextField
          type="number"
          inputProps={{ min: 0, max: 8 }}
          sx={{ marginBottom: "1rem", width: "100%" }}
          size="small"
          label="Year"
          value={newUserData.year ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleUpdateYear(event.target.value);
          }}
          id="yearInput"
          variant="filled"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              height: "35px",
              minWidth: "fit-content",
              alignSelf: "right",
            }}
            disabled={
              JSON.stringify(userProfile) == JSON.stringify(newUserData)
            }
            variant="contained"
            onClick={async () => {
              handleSave();
            }}
          >
            Save
          </Button>
          {errorMessage.length >= 0 && (
            <p className="ErrorMessage">{errorMessage}</p>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default EditProfileModal;
