import { Box, IconButton } from "@mui/material";
import { KeyboardDoubleArrowLeft } from "@mui/icons-material"; // Example icon

const ResizeIcon = () => {
  return (
    <Box
      sx={{
        height: "92vh",
        display: "flex",
        alignItems: "center",
        width: "0.25vw",
        cursor: "pointer",
        justifyContent: "center",
      }}
    >
      <IconButton aria-label="Resize Panel" sx={{ padding: "4px" }}>
        <KeyboardDoubleArrowLeft sx={{ color: "#1976D2" }} />
      </IconButton>
    </Box>
  );
};

export default ResizeIcon;
