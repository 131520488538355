import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { RedditSortFilter, RedditSortFilterMap } from "./WebInsightsTypes";

interface RedditSortSelectorProps {
  selectedFilter: RedditSortFilter;
  setSelectedFilter: (filter: RedditSortFilter) => void;
}
function RedditSortSelector({
  selectedFilter,
  setSelectedFilter,
}: RedditSortSelectorProps) {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedFilter(RedditSortFilterMap[event.target.value]);
  };

  return (
    <FormControl size="small">
      <InputLabel id="select-label">Sort Reddit</InputLabel>
      <Select
        labelId="select-label"
        id="select-visualization"
        value={selectedFilter}
        label="Select Visualization"
        onChange={(event) => handleChange(event)}
        color="primary"
      >
        {Object.values(RedditSortFilter).map((filter) => (
          <MenuItem key={filter} value={filter}>
            {filter}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RedditSortSelector;
