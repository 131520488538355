const serializeSetReplacer = (key: unknown, value: unknown) => {
  if (value instanceof Set) {
    return ["_isSet", ...value]
  }
  return value
}

const serializeSetReviver = (key: string, value: unknown) =>{
  if (key === "terms") {
    return new Set((value as []).slice(1))
  }
  return value
}

export {serializeSetReplacer, serializeSetReviver}
