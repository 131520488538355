export enum SectionType {
  lecture,
  tutorial,
  lab,
}

export enum Term {
  One,
  Two,
}

export enum Views {
  calendar,
  settings,
}

export const Term_String_Map: { [key in Term]: string } = {
  [Term.One]: "T1",
  [Term.Two]: "T2",
};

export type SectionDetail = {
  term: Term;
  days: string[];
  startTime: string;
  endTime: string;
  location?: string;
  dateRange: string;
};

export interface ISectionData {
  code: string;
  name: string;
  instructors: string[];
  sectionDetails: SectionDetail[];
  terms: Set<Term>
  session: string
  worklistNumber: number;
  color: string;
  courseID?: string;
}

export enum DayOfWeek {
  Sun,
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
}

export interface ICalendarEvent {
  Id: number;
  Subject: string;
  StartTime: Date;
  EndTime: Date;
  Section: ISectionData;
  Detail: SectionDetail;
}
