import React, { createContext, useState } from "react";
import { Position, PositionDefault } from "../ResearchTypes";

const PositionContext = createContext<{
  position: Position;
  setPositionState: (newPosition: Position) => void;
}>({
  position: PositionDefault,
  setPositionState: () => {},
});

export const PositionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [position, setPosition] = useState<Position>(PositionDefault);

  const setPositionState = (newPosition: Position) => {
    setPosition(newPosition);
    localStorage.setItem(
      "ResearchLayoutPositions",
      JSON.stringify(newPosition)
    );
  };

  const contextValue = {
    position,
    setPositionState,
  };

  return (
    <PositionContext.Provider value={contextValue}>
      {children}
    </PositionContext.Provider>
  );
};

export default PositionContext;
