import { Box, Divider, Link, Typography } from "@mui/material";
import { Map, APIProvider } from "@vis.gl/react-google-maps";
import { ICalendarEvent, ISectionData } from "../../../App.types";
import { MarkerWithPopup } from "src/containers/Direction/MainMap/MarkerWithPopup";
import "./SectionInfoPanel.css";
import fetchProfRating, { RMPData } from "./helpers/RateMyProfHelpers";
import SectionInstructors from "./SectionInstructor";
import { useEffect, useState } from "react";
import { rawLocationData } from "src/containers/Direction/Direction.types";
import { SectionPanelMapMarker } from "./SectionPanelMapMarker";
import { getRawLocationData } from "src/containers/Direction/directionsUtil";
import GradesComponent from "./GradesComponent/GradesComponent";
import SectionLocation from "./SectionLocation";

interface IProps {
  selectedEvent: ICalendarEvent | undefined;
  setSelectedEvent: (data: ICalendarEvent | undefined) => void;
}

const SectionInfoPanel = ({ selectedEvent, setSelectedEvent }: IProps) => {
  return selectedEvent ? (
    <div className="SectionInfoPanelContainer">
      <div
        className="SectionCourseHeader"
        style={{ backgroundColor: selectedEvent?.Section.color }}
      >
        <Typography variant="h4">{selectedEvent.Section.code}</Typography>
        <Typography variant="subtitle1">
          {selectedEvent.Section.name}
        </Typography>
      </div>
      <SectionInstructors selectedEvent={selectedEvent} />
      <div className="SectionDataHeader">Grade Information: </div>
      <GradesComponent selectedSection={selectedEvent.Section} />
      <SectionLocation selectedEvent={selectedEvent} />
    </div>
  ) : (
    <div>Please select a section for more information</div>
  );
};

export default SectionInfoPanel;
