import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import MainMap from "./MainMap/MainMap";
import SectionsPanel from "./SectionsPanel/SectionsPanel";
import { ISectionData } from "../../App.types";
import { useState } from "react";
import { IDirectionSection, ISectionPair } from "./Direction.types";
import { Grid } from "@mui/material";
import SectionsTopBar from "./SectionsTopBar/SectionsTopBar";
import ResizeIcon from "./ResizeIcon";
import { APIProvider } from "@vis.gl/react-google-maps";
import { IUserProfile } from "@shared/UserTypes";
import { useFirebaseAuth } from "../AuthPages/Firebase";

interface IProps {
  sections: ISectionData[];
}

const Direction = ({ sections }: IProps) => {
  const { user } = useFirebaseAuth();

  const [selectedSections, setSelectedSections] = useState<IDirectionSection[]>(
    []
  );
  const [activeSectionPair, setActiveSectionPair] =
    useState<ISectionPair | null>(null);
  const [sectionPairs, setSectionPairs] = useState<ISectionPair[] | null>([]);
  const [expandedDirectionsCard, setexpandedDirectionsCard] = useState<
    string | null
  >(null);

  return (
    <Grid container>
      <Grid item xs={12}>
        <SectionsTopBar
          sections={sections}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
          setSectionPairs={setSectionPairs}
          setActiveSectionPair={setActiveSectionPair}
          setExpandedDirectionsCard={setexpandedDirectionsCard}
        />
      </Grid>
      <Grid item xs={12}>
        <PanelGroup autoSaveId="mainpage" direction="horizontal">
          <Panel defaultSize={80}>
            <APIProvider
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
            >
              <MainMap
                activeSectionPair={activeSectionPair}
                sectionPairs={sectionPairs}
              />
            </APIProvider>
          </Panel>
          <PanelResizeHandle
            style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
            children={<ResizeIcon />}
          />
          <Panel>
            <SectionsPanel
              sections={sections}
              selectedSections={selectedSections}
              setSelectedSections={setSelectedSections}
              sectionPairs={sectionPairs}
              setSectionsPairs={setSectionPairs}
              setActiveSectionPair={setActiveSectionPair}
              activeSectionPair={activeSectionPair}
              expandedDirectionsCard={expandedDirectionsCard}
              setexpandedDirectionsCard={setexpandedDirectionsCard}
            />
          </Panel>
        </PanelGroup>
      </Grid>
    </Grid>
  );
};

export default Direction;
