import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface IProps {
  title: string;
  text: string;
  handleClose: () => void;
  handleContinue?: () => void;
}

const InfoModal = ({ title, text, handleClose, handleContinue }: IProps) => {
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {handleContinue && (
          <Button onClick={handleClose} autoFocus>
            Continue
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InfoModal;
