import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import React, { useEffect, useState } from "react";
import {
  distributionChartSettings,
  formatAveragesData,
  formatDistributionData,
  generateDistributionSeries,
  getGradesDistribution,
} from "./GradesGraphHelper";
import { AveragesData, DistributionData } from "./GradesTypes";
import { GradesVisualizations } from "./GradesTypes";
import SkeletonChart from "./SkeletonChart";
import { useMediaQuery } from "@mui/material";

interface GradesGraphProps {
  selectedCourse: string | null;
  selectedView: GradesVisualizations;
}

/*
  HOW TO ADD MORE VISUALIZATIONS:
   1. Add a new enum value to GradesVisualizations
   2. Add a new key-value pair to GradesVisualizationsMap
   3. Add a new Item to the dropdown in GradesViewSelector.tsx
   4. Create your graph using the data in the useEffect hook

  GradesGraph component is a wrapper for the different types of graphs that can be displayed and switches 
  between them based on the selected view
  SelectedView is a state that is passed down from the parent component and set by the GradesViewSelector
  in the top bar of its ResearchCard

 */
function GradesGraph({ selectedCourse, selectedView }: GradesGraphProps) {
  // Data structured specifically for distribution view plot
  const [distributionData, setDistributionData] = useState<
    DistributionData[] | null
  >(null);
  // Data structured specifically for averages view plot
  const [averageData, setAverageData] = useState<AveragesData[] | null>(null);

  const enableLegend = useMediaQuery("(min-width:1000px)"); //Custom breakpoint for enabling legend of charts

  // Gets the grades distribution for the selected course and sets the states for plotting
  useEffect(() => {
    if (selectedCourse) {
      getGradesDistribution(selectedCourse).then((data) => {
        setDistributionData(formatDistributionData(data || []));
        setAverageData(formatAveragesData(data || []));
      });
    }
  }, [selectedCourse]);

  const distributionChart = (
    <React.Fragment>
      {distributionData && (
        <BarChart
          dataset={distributionData}
          xAxis={[{ scaleType: "band", dataKey: "gradeRange" }]}
          series={generateDistributionSeries(distributionData)}
          {...distributionChartSettings}
          slotProps={{
            legend: {
              hidden: !enableLegend,
            },
          }}
        />
      )}
    </React.Fragment>
  );

  const averagesChart = (
    <React.Fragment>
      <LineChart
        xAxis={[
          {
            data: averageData?.map((data) => data.year) || [],
            scaleType: "band",
          },
        ]}
        series={[{ data: averageData?.map((data) => data.average) || [] }]}
      />
    </React.Fragment>
  );

  const gradeVisualizationMap = {
    [GradesVisualizations.Distributions]: distributionChart,
    [GradesVisualizations.Averages]: averagesChart,
  };

  return (
    <React.Fragment>
      {selectedCourse ? gradeVisualizationMap[selectedView] : <SkeletonChart />}
    </React.Fragment>
  );
}

export default GradesGraph;
