import { DayOfWeek, ICalendarEvent, ISectionData, SectionDetail, Term } from "../../../App.types";
import { testDataSectionsOne } from "../testData";
import { EventTemplate } from "./EventTemplate";
import { ScheduleComponent, Week, Inject, ViewsDirective, ViewDirective, EventRenderedArgs, PopupOpenEventArgs, Day, View, CellTemplateArgs} from '@syncfusion/ej2-react-schedule';
import "./Calendar.css"
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "src/Theme";
import { useEffect, useRef } from "react";

interface IProps {
  sections: ISectionData[];
  selectedTerm: Term;
  selectedEvent: ICalendarEvent | undefined;
  setSelectedEvent: (data: ICalendarEvent | undefined) => void;
}

const Calendar = ({
  sections,
  selectedTerm, 
  selectedEvent,
  setSelectedEvent
}: IProps) => {

  const scheduleRef = useRef<ScheduleComponent | null>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const viewDirectiveOption = isMobile ? 'Day' : 'Week'

  useEffect(() => {
    if(scheduleRef.current) {
      let scheduler = scheduleRef.current
  
      const prevButton = scheduler.element.querySelector('.e-prev') as HTMLButtonElement; 
      const nextButton = scheduler.element.querySelector('.e-next') as HTMLButtonElement;
      const toolbarElement = scheduler.element.querySelector('.e-schedule-toolbar-container') as HTMLElement;
      const dateElement = scheduler.element.querySelector('.e-date-range') as HTMLElement;
      const kebabMenu = scheduler.element.querySelector('.e-_nav') as HTMLElement;
      const todayIcon = scheduler.element.querySelector('.e-icon-today') as HTMLElement
  
      const toolbar = scheduler.element.querySelector('.e-schedule-toolbar-container') as HTMLElement
      if (toolbar) {
        toolbar.style.display = 'none';
      }
  
      let disableElementArr = [dateElement, kebabMenu, todayIcon]
  
      disableElementArr.forEach((e) => {
        if (e) {
          e.style.display = 'none';
        }
      })
  
      if (scheduler.activeView?.viewClass === 'e-day-view' ) {
        prevButton.disabled = false;
        nextButton.disabled = false;
        } else {
        if (toolbarElement) {
          toolbarElement.style.display = 'none';
       }
      }
    }
  }, [isMobile])
  

  const buildEvents = (data: ISectionData[]) => {
    console.log(data)
    let events: any = []
    data.forEach((section, i) => {
      //Term 2 in extension has duplicate SectionDetails, so need to combine them into one object
      let uniqueDetails = section.sectionDetails.reduce((acc: SectionDetail[], cur: SectionDetail) => {
        const existingTerm = acc.find(a => a.term === cur.term);
        if (existingTerm) {
          // If term already exists, update its dateRange
          existingTerm.dateRange = `${existingTerm.dateRange} - ${cur.dateRange}`;
        } else {
          // If term doesn't exist, add the current object
          acc.push(cur);
        }
        return acc;
      }, []);
      console.log(uniqueDetails)
      uniqueDetails.forEach((detail, j) => {
        if (detail.term === selectedTerm) {
          
          detail.days.forEach((day) => {
            let startHour = +detail.startTime.split(":")[0]
            let startMinute = +detail.startTime.split(":")[1]
            let endHour = +detail.endTime.split(":")[0]
            let endMinute = +detail.endTime.split(":")[1]
            
            events.push({
              Id: events.length + 1,
              Subject: section.name,
              StartTime: new Date(2024, 8, DayOfWeek[day as keyof typeof DayOfWeek] + 1, startHour, startMinute),
              EndTime: new Date(2024, 8, DayOfWeek[day as keyof typeof DayOfWeek] + 1, endHour, endMinute),
              // RecurrenceRule: `FREQ=WEEKLY;BYDAY=${day.substring(0,2).toUpperCase()}`  //Might be useful in future
              Section: section, //Pass this in so we can use the Section data in calendar rendering
              Detail: detail, //Used for event rendering, need specific detail since some section have multiple detail.
            })
          })
        }
      })
    })

    return events;
  }

  const onEventRendered = (args: EventRenderedArgs): void => {
    let color: string = args.data.Section.color as string;
    args.element.style.backgroundColor = color;
    args.element.style.width = '100%'
}

  const onPopupOpen = (args: PopupOpenEventArgs) => {
    setSelectedEvent(args.data ? args.data as ICalendarEvent : undefined)
    args.cancel = true; // disables built in popup
  };

  return (
    <div className="ScheduleContainer"> 
    <ScheduleComponent
    ref={scheduleRef}
    currentView='Week'
    minDate={new Date(2024, 8, 1)}
    maxDate={new Date(2024, 8, 7)}
    eventRendered={onEventRendered}
    popupOpen={onPopupOpen}

    eventSettings={{
      dataSource: buildEvents(sections),
      spannedEventPlacement: 'TimeSlot',
      allowAdding: false,
      allowDeleting: false,
      allowEditing: false,
      enableTooltip: false,
  }}>
      <ViewsDirective>
        <ViewDirective option={viewDirectiveOption} startHour={'08:00'} endHour={'22:00'} timeScale={{enable: true, interval: 60, slotCount: 2}} eventTemplate={EventTemplate} />
      </ViewsDirective>
      <Inject services={[Day, Week]} />
    </ScheduleComponent>
  </div>
  );
};

export default Calendar;
