import { Link, Stack, Typography } from "@mui/material";
import { ICalendarEvent } from "../../../App.types";
import "./SectionInfoPanel.css";
import fetchProfRating, { RMPData } from "./helpers/RateMyProfHelpers";
import { useEffect, useState } from "react";

interface IProps {
  selectedEvent: ICalendarEvent | undefined;
}

const SectionInstructors = ({ selectedEvent }: IProps) => {
  const [profRatings, setProfRatings] =
    useState<Record<string, RMPData | null>>();

  const isVancouver = selectedEvent?.Section.code.substring(4, 6) === "_V";

  useEffect(() => {
    fetchInstructorRatings();
  }, [selectedEvent]);

  const fetchInstructorRatings = async () => {
    if (!selectedEvent) {
      return;
    }

    const fetches: any[] = [];
    selectedEvent?.Section.instructors.forEach((i) => {
      fetches.push(fetchProfRating(i, isVancouver));
    });

    const results = await Promise.all(fetches);
    const ratings = results.reduce<Record<string, RMPData>>(
      (acc, item, index) => {
        acc[selectedEvent.Section.instructors[index]] = item;
        return acc;
      },
      {}
    );
    setProfRatings(ratings);
  };

  const buildInstructor = (instructor: string): JSX.Element => {
    let data = profRatings ? profRatings[instructor] : null;
    let name = instructor.replace(/ /g, "+");
    let link = data?.link || `https://www.google.com/search?q=${name}`;
    console.log(name);
    return (
      <div className="SectionData" key={`#${instructor}`}>
        <Link href={link} target="_blank" rel="noopener">
          {instructor}
        </Link>
        <div style={{ paddingLeft: "10px" }}>
          {data?.rating || "unavilable"}
        </div>
      </div>
    );
    // if (data) {
    //   console.log(data);
    //   return (
    //     <div className="SectionData" key={`#${instructor}`}>
    //       <Link href={data.link} target="_blank" rel="noopener">
    //         {instructor}
    //       </Link>
    //       <div style={{ paddingLeft: "10px" }}>{data.rating}</div>
    //     </div>
    //   );
    // } else {
    //   return <div className="SectionData">{instructor}</div>;
    // }
  };

  const getInstructorDisplay = (
    instructors: string[]
  ): JSX.Element | JSX.Element[] => {
    if (instructors?.length > 0) {
      return instructors.map((i) => {
        return buildInstructor(i);
      });
    } else {
      return <div className="SectionData">Instructor data missing</div>;
    }
  };

  return (
    <div>
      <div className="SectionDataHeader">Instructors:</div>
      {getInstructorDisplay(
        selectedEvent ? selectedEvent?.Section.instructors : []
      )}
    </div>
  );
};
export default SectionInstructors;
