import { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Slide,
  AppBar,
  Toolbar,
  Button,
  Tooltip,
} from "@mui/material";
import PositionContext from "../Contexts/PositionContext";
import { PositionDefault } from "../ResearchTypes";
import EditLayoutContext from "../Contexts/EditLayoutContext";


function LayoutEditor() {
  const [open, setOpen] = useState(false);
  const { setPositionState } = useContext(PositionContext);
  const { setResetKey, setEditLayout} = useContext(EditLayoutContext);

  useEffect(() => {
    setOpen(true);
    setEditLayout(true);
  }, []);

  const handleResetLayout = () => {
    setPositionState(PositionDefault);
    setResetKey(true);
  };


  const handleClose = () => {
    setOpen(false);
    setEditLayout(false);
  };

  return (
    <>
      <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1000 }}
        >
          <AppBar color="primary" position="static" elevation={1}>
            <Toolbar>
              <Typography variant="h5" color="white" sx={{ flexGrow: 1 }}>
                Layout Editor
              </Typography>
              <Tooltip title="Drag the cards to rearrange. Drag in between cards to resize" placement="left">

              <Button
                variant="outlined"
                color="inherit"
                sx={{ justifyContent: "flex-end", marginLeft: "1%" }}
              >
                Help
              </Button>
              </Tooltip>
              <Button
                variant="outlined"
                onClick={handleResetLayout}
                color="inherit"
                sx={{ justifyContent: "flex-end", marginLeft: "1%" }}
              >
                Reset Layout
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                color="inherit"
                sx={{ justifyContent: "flex-end", marginLeft: "1%" }}
              >
                Close
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
      </Slide>
    </>
  );
}

export default LayoutEditor;
