export interface IReview {
  reviewId: string;
  userId: string;
  userName: string;
  userProfilePicture: string;
  comments: string;
  courseCode: string;
  professor: string;
  rating: string;
  term: string;
  year: string;
  datePosted: string;
  upvotes: number;
  downvotes: number;
}

// 1 for upvote, -1 for downvote, undefined for no vote
export interface IUserVote {
  [reviewId: string]: 1 | -1 | undefined;
}

export enum ReviewSortFilter {
  Newest = "Newest",
  Oldest = "Oldest",
  Upvotes = "Upvotes",
  MostHelpful = "Most Helpful",
  LeastHelpful = "Least Helpful",
}

export const ReviewSortFilterMap: Record<string, ReviewSortFilter> = {
  Newest: ReviewSortFilter.Newest,
  Oldest: ReviewSortFilter.Oldest,
  "Most Helpful": ReviewSortFilter.MostHelpful,
  "Least Helpful": ReviewSortFilter.LeastHelpful,
};
