import axios from "axios";
import { AveragesData, GradesData, rawGradesData } from "./GradesTypes";
import { DistributionData } from "./GradesTypes";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

// Get the grades distribution for a course from the UBC Grades API
const getGradesDistribution = async (
  courseCode: string
): Promise<GradesData[] | null> => {
  const [code, courseNumber, campus] = getParts(courseCode);

  let url = `https://ubcgrades.com/api/v3/course-statistics/distributions/${campus}/${code}/${courseNumber}`;
  try {
    const rawGradesData = await axios.get(url);
    const gradesData: GradesData[] = formatRawGradesData(rawGradesData.data);
    return gradesData;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Extract the course code, number, and campus from the course code for use in API call
const getParts = (courseCode: string): string[] => {
  const parts = courseCode.split(" ");

  const code = parts[0].split("_")[0];
  const num = parts[1].split("-")[0];

  let campus = parts[0].split("_")[1];
  if (campus === "V") {
    campus = "UBCV";
  } else {
    campus = "UBCO";
  }

  return [code, num, campus];
};

// This is not the most readable function, but it formats the raw data from the API by:
// 1. Reorder the grades so that "<50%" is always the first key
// 2. Remove any null counts for grades that were not reported in recent years
// 3. Convert the grades to a dictionary with the grade range as the key and the count as the value
const formatRawGradesData = (rawGradesData: rawGradesData[]): GradesData[] => {
  const latest5Years = removeNullCounts(rawGradesData);

  const formattedData: GradesData[] = latest5Years.map((yearData) => ({
    // Reorder the grades so that "<50%" is always the first key
    grades: {
      "<50%": yearData.grades["<50%"],
      ...Object.entries(yearData.grades)
        .filter(([key]) => key !== "<50%")
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    },
    session: yearData.year + yearData.session,
  }));

  return formattedData;
};

// Data returned contains sub 50% grades, which were only reported back in the 90s so show as null for recent years
function removeNullCounts(data: rawGradesData[]): rawGradesData[] {
  return data.map((item) => ({
    ...item,
    grades: Object.fromEntries(
      Object.entries(item.grades).filter(([_, count]) => count !== null)
    ),
  }));
}

// Format the data to be used in the range view plot which has the grade ranges as the x-axis and the counts as the y-axis
function formatDistributionData(data: GradesData[]): DistributionData[] {
  const gradeRanges = new Set<string>();
  const sessions = new Set<string>();
  const latest5Years = data.slice(-5);

  latest5Years.forEach((item) => {
    Object.keys(item.grades).forEach((gradeRange) =>
      gradeRanges.add(gradeRange)
    );
    sessions.add(item.session);
  });

  const resultData = new Map<string, any>();

  gradeRanges.forEach((gradeRange) => {
    resultData.set(gradeRange, {});
    sessions.forEach((session) => {
      resultData.get(gradeRange)[session] = 0;
    });
  });

  latest5Years.forEach((item) => {
    Object.entries(item.grades).forEach(([gradeRange, count]) => {
      resultData.get(gradeRange)[item.session] += count;
    });
  });

  return Array.from(resultData.entries()).map(([gradeRange, counts]) => ({
    gradeRange,
    ...counts,
  }));
}

// Format the data to be used in the averages view plot which has the years as the x-axis and the averages as the y-axis
function formatAveragesData(gradesData: GradesData[]): AveragesData[] {
  return gradesData.map((data) => {
    const year = data.session;

    let totalStudents = 0;
    let totalGradePoints = 0;

    for (const [gradeRange, count] of Object.entries(data.grades)) {
      const [lowerBound, upperBound] = gradeRange.split("-");

      if (upperBound) {
        const lower = parseFloat(lowerBound.replace("<", ""));
        const upper = parseFloat(upperBound);
        const midpoint = (lower + upper) / 2;
        totalStudents += count;
        totalGradePoints += midpoint * count;
      } else {
        const lower = parseFloat(lowerBound.replace("<", ""));
        totalStudents += count;
        totalGradePoints += lower * count;
      }
    }
    const average = totalGradePoints / totalStudents;

    return { year, average };
  });
}

// Generate the series for the distribution plot
const generateDistributionSeries = (rangeViewData: DistributionData[]) => {
  const sessions = new Set<string>();
  rangeViewData.forEach((range: DistributionData) => {
    for (const key in range) {
      if (key !== "gradeRange") {
        sessions.add(key);
      }
    }
  });

  return Array.from(sessions).map((session) => ({
    dataKey: session,
    label: session,
  }));
};

// UI settings for the distribution plot
const distributionChartSettings = {
  yAxis: [
    {
      label: "Reported #",
    },
  ],
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-10px, 0)",
    },
  },
};

export {
  getGradesDistribution,
  formatDistributionData,
  formatAveragesData,
  generateDistributionSeries,
  distributionChartSettings,
};
