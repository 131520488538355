import React, { useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import {
  DarkBackgroundColor,
  LightBackgroundColor,
  PrimaryColor,
  SecondaryColor,
  PanelColor,
} from "src/Theme";
import Divider from "@mui/material/Divider";
import { useFirebaseAuth } from "./Firebase";
import "./styles.css";

const SigninPage = () => {
  const { user, signInFB, signOutFB } = useFirebaseAuth();
  const [userEmailInput, setUserEmailInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSignin = async () => {
    try {
      await signInFB(userEmailInput, passwordInput);
      window.location.replace("/home");
    } catch (err) {
      console.log(err);
      setErrorMessage(`Error signing in, please validate your credentials`);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "600px",
        minHeight: "92vh",
        backgroundColor: LightBackgroundColor,
        marginTop: "20px",
      }}
    >
      <Box
        sx={{
          marginTop: "5rem",
          backgroundColor: PanelColor,
          padding: "1.25rem",
          borderRadius: "10px",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.1), 0px 1px 3px 0px rgba(0,0,0,0.08);",
        }}
      >
        <Typography
          sx={{ marginBottom: "0.25rem", textAlign: "left" }}
          variant="h3"
        >
          Sign in
        </Typography>
        <Box
          className="InputContainer"
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "85%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <TextField
            sx={{ marginBottom: "1rem", width: "100%" }}
            size="small"
            label="Email"
            value={userEmailInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUserEmailInput(event.target.value);
            }}
            id="standard-basic"
            variant="filled"
          />
          <TextField
            sx={{ marginBottom: "0.5rem", width: "100%" }}
            size="small"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={passwordInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPasswordInput(event.target.value);
            }}
            id="standard-basic"
            variant="filled"
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              width: "100%",
            }}
          >
            <a
              style={{
                alignItems: "left",
                marginBottom: "1rem",
                textDecoration: "none",
                color: SecondaryColor,
              }}
              href=""
            >
              Forgot password?
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "baseline",
              width: "100%",
            }}
          >
            <Button
              sx={{
                height: "35px",
                minWidth: "fit-content",
                alignSelf: "right",
              }}
              variant="contained"
              onClick={() => {
                handleSignin();
              }}
              disabled={userEmailInput.length <= 0 || passwordInput.length <= 0}
            >
              Sign in
            </Button>
            {errorMessage.length >= 0 && (
              <p className="ErrorMessage">{errorMessage}</p>
            )}
          </Box>
          <Divider
            sx={{
              color: "GrayText",
              width: "100%",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            or
          </Divider>
          <Button
            sx={{
              height: "35px",
              alignSelf: "right",
              width: "50%",
              minWidth: "fit-content",
            }}
            variant="outlined"
            onClick={() => {
              window.location.replace("/register");
            }}
            disabled={userEmailInput.length < 0 || passwordInput.length < 0}
          >
            Create Account
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default SigninPage;
