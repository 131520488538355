import { useCallback, useContext, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { theme } from "src/Theme";
import { DRAG_TYPE, Position } from "../ResearchTypes";
import PositionContext from "../Contexts/PositionContext";
import EditLayoutContext from "../Contexts/EditLayoutContext";
import LayoutPlaceholder from "./LayoutPlaceHolder";

interface IDraggableComponentProps {
  component: { component: JSX.Element; name: string };
  index: number;
}

export const DraggableComponent = ({
  component,
  index,
}: IDraggableComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { position, setPositionState } = useContext(PositionContext);
  const { editLayout } = useContext(EditLayoutContext);

  const swap = useCallback(
    (dragIndex: number, dropIndex: number, positionCopy: Position) => {
      //Copy is needed because for some reason the position context is some how getting updated right before Swap is called
      //and Im not sure why. Doing it this way ensure the drag/drop indices line up with the position object
      const newPosition = { ...positionCopy };
      const entries = Object.entries({ ...newPosition });
      const dragName = entries.find(([key, value]) => value === dragIndex)?.[0];
      const hoverName = entries.find(
        ([key, value]) => value === dropIndex
      )?.[0];
      if (!dragName || !hoverName) {
        return;
      }

      const temp = newPosition[dragName as keyof typeof newPosition];
      newPosition[dragName as keyof typeof newPosition] =
        newPosition[hoverName as keyof typeof newPosition];
      newPosition[hoverName as keyof typeof newPosition] = temp;
      setPositionState(newPosition);
    },
    []
  );

  const [collectedProps, drop] = useDrop({
    accept: DRAG_TYPE,

    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        handlerId: monitor.getHandlerId(),
      };
    },

    drop(item: any, monitor: any) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const dropIndex = index;

      swap(dragIndex, dropIndex, position);
      item.index = dropIndex;
    },
  });

  //useDrag allows us to interact with the drag source
  const [collectedDragProps, drag, preview] = useDrag({
    type: DRAG_TYPE,
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => editLayout,
  });

  const backgroundColor = collectedProps.isOver
    ? theme.palette.primary.light
    : theme.palette.grey[500];

  drag(drop(ref));

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      {editLayout ? (
        <LayoutPlaceholder
          name={component.name}
          backgroundColor={backgroundColor}
          isOver={collectedProps.isOver}
        />
      ) : (
        component.component
      )}
    </div>
  );
};
