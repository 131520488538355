import {
  Card,
  CardContent,
  Collapse,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  MobileStepper,
  Button,
  Chip,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useEffect, useState, useRef } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { learnMoreContent, learnMoreImages } from "./LandingPageContent";

export default function LearnMore() {
  const [loadAnimation, setLoadAnimation] = useState(false);
  const learnMoreRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = learnMoreImages.length;
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setLoadAnimation(true);
      }
    });
    observer.observe(learnMoreRef.current!);
    return () => observer.disconnect();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Collapse in={loadAnimation} {...(loadAnimation ? { timeout: 1000 } : {})}>
      <Card
        sx={{
          maxWidth: isMobile ? "80%" : 645,
          background: "rgba(0,0,0,0.3)",
          margin: "auto",
        }}
        ref={learnMoreRef}
      >
        <Box
          sx={{
            height: 440,
            flexGrow: 1,
            display: isMobile ? "none" : "block",
            backgroundColor: "rgba(0,0,0,0.1)",
          }}
        >
          <AutoPlaySwipeableViews
            axis={"x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {learnMoreImages.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      display: "block",
                      height: "380px",
                      margin: "0 auto",
                      paddingTop: "20px",
                    }}
                    src={step.imgPath}
                    title={step.label}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                style={{ color: "#fff" }}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                style={{ color: "#fff" }}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
            sx={{ background: "rgba(0,0,0,0.0)" }}
          />
        </Box>
        <CardContent sx={{ background: "#fff" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="h1"
            color={"rgba(0, 0, 0, 0.87)"}
            style={{ fontWeight: "bold", display: "grid", justifyContent: "center"}}
          >
            {learnMoreContent.title}
          </Typography>
          <Typography
            variant="body2"
            color="rgba(0, 0, 0, 0.87)"
            component="p"
            sx={{ fontSize: "1.1rem" }}
          >
            {learnMoreContent.body}
          </Typography>
          <Chip
            label="Download On Chrome Extension Store"
            component="a"
            href="https://chromewebstore.google.com/detail/ubc-workday-side-by-side/gonljejijjjmjccdbjokcmmdfmlincmh?hl=en&authuser=0"
            style={{ marginRight: "10px", marginTop: "10px" }}
            clickable
          />

          <Chip
            label="Join Discord"
            component="a"
            href="https://discord.gg/5VZdPh2dmS"
            style={{ marginLeft: "10px", marginTop: "10px" }}
            clickable
          />
        </CardContent>
      </Card>
    </Collapse>
  );
}