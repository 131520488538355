import {
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Box,
  List,
  ListItem,
  useMediaQuery,
  Button,
  Collapse,
} from "@mui/material";

import { IDirectionSection, ISectionPair, Step } from "../Direction.types";
import { theme } from "src/Theme";
import MobileSectionsTopBar from "../SectionsTopBar/MobileSectionsTopBar";
import { ISectionData } from "src/App.types";
import { sortSections } from "../directionsUtil";
import { useEffect, useState } from "react";
import { Directions } from "@mui/icons-material";

interface IProps {
  selectedSections: IDirectionSection[];
  sections: ISectionData[];
  sectionPairs: ISectionPair[] | null;
  activeSectionPair: ISectionPair | null;
  expandedDirectionsCard: string | null;
  setSectionsPairs: (data: ISectionPair[] | null) => void;
  setSelectedSections: (data: IDirectionSection[]) => void;
  setActiveSectionPair: (data: ISectionPair | null) => void;
  setexpandedDirectionsCard: (data: string | null) => void;
}

const SectionsPanel = ({
  selectedSections,
  sections,
  sectionPairs,
  activeSectionPair,
  expandedDirectionsCard,
  setSectionsPairs,
  setSelectedSections,
  setActiveSectionPair,
  setexpandedDirectionsCard,
}: IProps) => {
  const isLargeDisplay = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const sortedSections = sortSections(selectedSections);

  const formatBriefLocation = (location: string): string => {
    if (!location) return "Not Available";

    return `${location.split("-")[0]}  ${location.split(" ")[2]}`;
  };

  const handleDirectionsButtonClick = (startSectionCode: string) => {
    if (!sectionPairs) return;
    const sectionPair = sectionPairs.find(
      (pair) => pair.startSection.code === startSectionCode
    );

    if (!sectionPair) return;
    console.log(sectionPair);
    setActiveSectionPair({
      startSection: sectionPair.startSection,
      startLocation: sectionPair.startLocation,
      endSection: sectionPair.endSection,
      endLocation: sectionPair.endLocation,
      directions: sectionPair.directions,
    });
    if (expandedDirectionsCard !== startSectionCode) {
      setexpandedDirectionsCard(startSectionCode);
    } else {
      setexpandedDirectionsCard(null);
    }
  };

  const isLastSection = (section: IDirectionSection): boolean => {
    return section.code === selectedSections[selectedSections.length - 1].code;
  };

  const hasDirections = (section: IDirectionSection): boolean => {
    if (!sectionPairs) return false;

    const sectionPair = sectionPairs.find(
      (pair) => pair.startSection.code === section.code
    );

    if (!sectionPair) return false;

    if (!sectionPair.startSection.location || !sectionPair.endSection.location)
      return false;

    return true;
  };

  const findSectionPair = (section: IDirectionSection): ISectionPair | null => {
    if (!sectionPairs) return null;

    const sectionPair = sectionPairs.find(
      (pair) => pair.startSection.code === section.code
    );

    if (!sectionPair) return null;

    return sectionPair;
  };

  const formatDirectionStep = (step: Step) => {
    const parts = step.html_instructions.split(
      /<div style="font-size:0.9em">(.+?)<\/div>/
    );
    const directionText = parts[0].replace(/<b>/g, "").replace(/<\/b>/g, "");
    const additionalInfo = parts.length > 1 ? parts[1].trim() : null;

    const directionSpan = (
      <span>
        {directionText} - {step.duration.text} | {step.distance.text}
      </span>
    );

    const infoSpan = additionalInfo ? (
      <span style={{ fontSize: ".9em", color: "gray" }}>
        {` ${additionalInfo}`}{" "}
      </span>
    ) : null;

    return (
      <>
        {directionSpan}
        {infoSpan && (
          <>
            <br />
            {infoSpan}
          </>
        )}
        <br />
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        gap: "30px",
        height: "92vh",
        overflowY: "auto",
      }}
    >
      {isMobile && (
        <MobileSectionsTopBar
          setSectionPairs={setSectionsPairs}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
          sections={sections}
          setActiveSectionPair={setActiveSectionPair}
          setExpandedDirectionsCard={setexpandedDirectionsCard}
        />
      )}
      <List sx={{ height: "95%", width: "100%", overflow: "auto" }}>
        {sortedSections.map((section) => (
          <ListItem
            key={section.code}
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Card sx={{ width: "100%" }} key={section.code}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={5} sx={{ display: "grid" }}>
                    <Typography variant="h5">{section.code}</Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      align="left"
                    >
                      {section.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: isMobile ? "flex-start" : "flex-end",
                        }}
                      >
                        <Chip
                          label={
                            (isLargeDisplay
                              ? section.location
                              : formatBriefLocation(section.location)) ||
                            "Not Available"
                          }
                          style={{ alignSelf: "center" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: isMobile ? "flex-start" : "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Chip
                          label={section.startTime + " - " + section.endTime}
                          style={{ alignSelf: "center" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {findSectionPair(section)?.directions?.distance === "" ? (
                    <Chip
                      variant="outlined"
                      sx={{
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                        },
                        marginTop: "5px",
                        color: "text.secondary",
                      }}
                      label="We don't have building data for this section, map functionality will be disabled."
                    />
                  ) : (
                    !isLastSection(section) &&
                    hasDirections(section) && (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "grid", marginTop: "10px" }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleDirectionsButtonClick(section.code)
                          }
                        >
                          Directions To Next Class
                        </Button>
                      </Grid>
                    )
                  )}
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ width: "100%" }} key={`${section.code} Expanded`}>
              <Collapse in={expandedDirectionsCard === section.code}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={9} sx={{ marginBottom: "10px" }}>
                      <Typography variant="h5">
                        {activeSectionPair?.directions?.duration} |{" "}
                        {activeSectionPair?.directions?.distance}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                      }}
                    >
                      <Directions color="primary" />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <Typography variant="body2">
                          {activeSectionPair?.directions?.walkingSteps.map(
                            (step, index) => (
                              <div key={index}>{formatDirectionStep(step)}</div>
                            )
                          )}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Collapse>
            </Card>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SectionsPanel;
