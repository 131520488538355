import { IReview } from "../ReviewTypes";
import axios from "axios";
import { env } from "src";

export const validateReview = (post: IReview) => {
  if (post.userId == null) {
    throw new Error("You must be logged in to leave a review.");
  }
  if (post.courseCode.length == 0) {
    throw new Error("The post's course code cannot be empty.");
  }

  if (post.comments.length == 0) {
    throw new Error("The post's comments cannot be empty.");
  }

  if (post.professor.length == 0) {
    throw new Error("The post's professors cannot be empty.");
  }

  if (
    post.rating.length == 0 ||
    parseInt(post.rating) < 0 ||
    parseInt(post.rating) > 5
  ) {
    throw new Error("The post's rating must be between 0 and 5.");
  }

  if (post.term.length == 0) {
    throw new Error("The post's term cannot be empty.");
  }

  if (post.year.length == 0) {
    throw new Error("The post's year cannot be empty.");
  }
};

export const submitReview = async (post: IReview) => {
  try {
    let url = `${env.REACT_APP_BACKEND_BASE_URL}/api/course-reviews/review`;
    let resp = await axios.post(url, post);
    return "Submission Successful";
  } catch (err) {
    console.error(err);
    throw `Error submitting: ${err}`;
  }
};
