import { IPostComment } from "@shared/SocialTypes";
import axios from "axios";

export const SendComment = async (
  comment: IPostComment,
  postId: string,
  postUserId: string
) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/social/userPosts/updateComments`,
      { comment, postId: postId, postUserId: postUserId }
    );
  } catch (err) {
    console.error(err);
    throw new Error("Error sending comment, please try again.");
  }
};

export const validateComment = (comment: IPostComment) => {
  if (comment.message.length <= 0) {
    throw new Error("comment cannot be empty");
  }
  if (comment.UserId.length == 0) {
    throw new Error("cannot reply without being logged in");
  }
  if (comment.CommentId.length == 0) {
    throw new Error("CommentId cannot be empty");
  }
};

export const UpdateLikedUserList = async (
  likedUserIdList: string[],
  postId: string,
  postUserId: string
) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/social/userPosts/updateLikes`,
      { likedUserIdList, postId: postId, postUserId: postUserId }
    );
  } catch (err) {
    throw new Error("Error interacting with post, please try again.");
  }
};
