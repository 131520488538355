import { ISectionData } from "./App.types";
import axios from "axios";
import { DirectionsBaseCase } from "./containers/Direction/Direction.types";
import { select } from "@syncfusion/ej2-base";

export const getUrl = () => {
  if (window.location.href.indexOf("localhost") !== -1) {
    return "http://localhost:8080";
  } else {
    return "https://ubc-student-hub-ho6ay.ondigitalocean.app";
  }
};

export const addSections = async (
  userId: string,
  sections: ISectionData[],
  onSuccess: () => void,
  onFail: () => void
) => {
  const url = `${getUrl()}/api/sections`;
  axios
    .post(url, { userId: userId, sections: sections })
    .then((response) => {
      if (response.status === 201) {
        onSuccess();
      } else {
        onFail();
      }
    })
    .catch((err) => {
      console.log(err);
      onFail();
    });
};

export const getUserSections = async (
  userId: string
): Promise<ISectionData[]> => {
  const url = `${getUrl()}/api/sections/${userId as string}`;
  try {
    let response = await axios.get(url);
    let sections: ISectionData[] = response.data.sections;
    if (sections) return sections;
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getRoomData = async (roomCode: string) => {
  const url = `${getUrl()}/api/rooms/${roomCode as string}`;
  try {
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const fetchDirections = async (
  startLocationID: string,
  endLocationID: string
) => {
  const url = `${getUrl()}/api/directions/${startLocationID}/${endLocationID}`;
  try {
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    return DirectionsBaseCase;
  }
};

// FUNCTION TO ACCESS ENDPOINT THAT SCANS ALL OF THE ROOMS IN THE ROOMS TABLE FOR UPDATING THE ROOMS TABLE. //
// export const getAllRoomsData = async () => {
//     const url = `${getUrl()}/api/rooms/all`;
//     try {
//       let response = await axios.get(url);
//       return response.data;
//     } catch (error) {
//       console.error(error);
//       return [];
//     }
//   };

export const verifySections = (sections: any[]) => {
  for (let section of sections) {
    if (section.courseID === undefined) {
      return false;
    }
  }
  return true;
};

export const generateId = () => {
  return `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
};

export const parseCourseParts = (
  selectedCourse: string
): [string, string, string] => {
  const courseCode = selectedCourse.split(" ")[0].split("_")[0];
  const courseNum = selectedCourse.split(" ")[1].split("-")[0];

  let campus = selectedCourse.split(" ")[0].split("_")[1];
  campus = campus === "V" ? "UBCV" : "UBCO"
  return [courseCode, courseNum, campus];
};
