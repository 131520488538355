export interface GradesData {
  grades: {
    [gradeRange: string]: number;
  };
  session: string;
}

// Format of the raw data from the API
export interface rawGradesData {
  campus: string;
  course: string;
  detail: string;
  grades: {
    [gradeRange: string]: number;
  };
  session: string;
  subject: string;
  year: string;
}

export type DistributionData = {
  gradeRange: `${string}%`;
} & {
  [key: `${number}${"W" | "S"}`]: number;
};

export type AveragesData = {
  year: string;
  average: number;
};

// If adding new visualizations, add them here
export enum GradesVisualizations {
  Distributions = "Historical Distributions",
  Averages = "Historical Averages",
}

// Map of visualization names to the enum. If adding new visualizations, add them here
export const GradesVisualizationsMap: Record<string, GradesVisualizations> = {
  "Historical Distributions": GradesVisualizations.Distributions,
  "Historical Averages": GradesVisualizations.Averages,
};
