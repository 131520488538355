export interface RedditPost {
  title: string;
  upvotes: number;
  url: string;
  Date: Date;
}

export interface RawPostData {
  data: {
    title: string;
    selftext: string;
    ups: number;
    url: string;
    created_utc: number;
  };
}

export interface GooglePost {
  title: string;
  link: string;
}

export enum RedditSortFilter {
  Newest = "Newest",
  Oldest = "Oldest",
  Upvotes = "Upvotes",
}

export const RedditSortFilterMap: Record<string, RedditSortFilter> = {
  Newest: RedditSortFilter.Newest,
  Oldest: RedditSortFilter.Oldest,
  Upvotes: RedditSortFilter.Upvotes,
};
