import { Avatar, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { SecondaryColor } from "src/Theme";
import { IUserProfile } from "@shared/UserTypes";

interface IUserProfileProps {
  userData: IUserProfile;
}

const UserCard = ({ userData }: IUserProfileProps) => {
  return (
    <Card sx={{ display: "flex", flexDirection: "row" }}>
      <Grid item sx={{ marginLeft: "8px", minWidth: "100px" }} xs={3}>
        <Avatar
          alt="User Profile Picture"
          src={userData.picture ?? "/static/images/avatar/2.jpg"}
          sx={{
            width: "100px",
            height: "100px",
            margin: "10px 0px",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.replace(`/profile/${userData.UserId}`);
          }}
        />
      </Grid>
      <Grid
        item
        sx={{
          textAlign: "left",
          marginLeft: "5%",
          padding: "5px 5px",
        }}
      >
        <Typography sx={{ fontSize: "1.6rem" }} variant="h5">
          {userData.userName}
        </Typography>
        <Typography variant="h6" color={SecondaryColor}>
          {userData.major ?? "unknown major"}
          {" | Year: " + (userData.year ?? "unknown")}
        </Typography>
        <Typography variant="body1">
          {`${
            userData.followerUserIds ? userData.followerUserIds.length : "0"
          } Followers | ${
            userData.followingUserIds ? userData.followingUserIds.length : "0"
          } Following`}
        </Typography>
      </Grid>
    </Card>
  );
};

export default UserCard;
