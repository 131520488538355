import React, { useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import {
  DarkBackgroundColor,
  LightBackgroundColor,
  PrimaryColor,
  SecondaryColor,
  PanelColor,
} from "src/Theme";
import Divider from "@mui/material/Divider";
import { useFirebaseAuth } from "./Firebase";
import { createNewUserProfile } from "./Authhelper";
import "./styles.css";
import {
  USER_NAME_MAX_CHARS,
  USER_NAME_MIN_CHARS,
} from "../Profile/ProfileHelper";

const RegisterPage = () => {
  const { registerUserWithEmail } = useFirebaseAuth();
  const [userEmailInput, setUserEmailInput] = useState<string>("");
  const [usernameInput, setUsernameInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const [passwordInputTwo, setPasswordInputTwo] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleRegister = async () => {
    if (userEmailInput.length == 0) {
      setErrorMessage("Email cannot be empty.");
      return;
    }
    if (
      usernameInput.length < USER_NAME_MIN_CHARS ||
      usernameInput.length > USER_NAME_MAX_CHARS
    ) {
      setErrorMessage(
        `Username must be between ${USER_NAME_MIN_CHARS}-${USER_NAME_MAX_CHARS} characters`
      );
      return;
    }
    if (passwordInput != passwordInputTwo) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    if (passwordInput.length < 8) {
      setErrorMessage("Password must be atleast 8 characters.");
      return;
    }
    try {
      let userData = await registerUserWithEmail(userEmailInput, passwordInput);
      if (userData) {
        await createNewUserProfile(userData.uid, usernameInput);
      }
      window.location.replace("/home");
    } catch (err: any) {
      setErrorMessage(`Error creating account: ${err.code}`);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "600px",
        minHeight: "92vh",
        backgroundColor: LightBackgroundColor,
        marginTop: "20px",
      }}
    >
      <Box
        sx={{
          marginTop: "5rem",
          backgroundColor: PanelColor,
          padding: "1.25rem",
          borderRadius: "10px",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.1), 0px 1px 3px 0px rgba(0,0,0,0.08);",
        }}
      >
        <Typography
          sx={{ marginBottom: "0.25rem", textAlign: "left" }}
          variant="h3"
        >
          Create account
        </Typography>
        <Box
          className="InputContainer"
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "85%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <TextField
            sx={{ marginBottom: "0.5rem", width: "100%" }}
            size="small"
            label="Email"
            required
            value={userEmailInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUserEmailInput(event.target.value);
            }}
            id="standard-basic"
            variant="filled"
          />
          <TextField
            sx={{ marginBottom: "1.25rem", width: "100%" }}
            size="small"
            label="Username"
            required
            value={usernameInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUsernameInput(event.target.value);
            }}
            id="standard-basic"
            variant="filled"
          />
          <TextField
            sx={{ marginBottom: "0.5rem", width: "100%" }}
            size="small"
            label="Password"
            required
            type="password"
            value={passwordInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPasswordInput(event.target.value);
            }}
            id="standard-basic"
            variant="filled"
          />
          <TextField
            sx={{ marginBottom: "0.5rem", width: "100%" }}
            size="small"
            label="Confirm Password"
            required
            type="password"
            value={passwordInputTwo}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPasswordInputTwo(event.target.value);
            }}
            id="standard-basic"
            variant="filled"
          />
        </Box>
        {errorMessage.length > 0 && (
          <p className="ErrorMessage">{errorMessage}</p>
        )}
        <Button
          sx={{
            height: "35px",
            alignSelf: "right",
            width: "50%",
            minWidth: "fit-content",
            marginTop: "0.5rem",
          }}
          variant="contained"
          onClick={() => {
            handleRegister();
          }}
          disabled={userEmailInput.length < 0 || passwordInput.length < 0}
        >
          Create Account
        </Button>
      </Box>
    </Container>
  );
};
export default RegisterPage;
