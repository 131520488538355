import { IUserProfile } from "@shared/UserTypes";
import axios from "axios";

export const createNewUserProfile = async (
  userId: string,
  userName: string
) => {
  const newUserProfile: IUserProfile = {
    UserId: userId,
    userName: userName,
    fullName: undefined,
    picture: undefined,
    followerUserIds: [],
    followingUserIds: [],
    major: undefined,
    year: undefined,
    about: undefined,
    searchName: userName.toLowerCase(),
  };

  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users`,
      newUserProfile
    );
    return newUserProfile;
  } catch (err) {
    console.error(err);
    throw new Error(`failed to create user ${err}`);
  }
};
