import { Chip } from "@mui/material";
export const heroImage = {
  alt: "IKB Library",
  title: `By CjayD - https://www.flickr.com/photos/85424459@N08/7835821172, CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=24011326`,
  source: require("../../assets/Irving_K._Barber_Library.jpg"),
};

export const learnMoreImages = [
  {
    imgPath: require("../../assets/logo512.png"),
    label: "Logo",
  },
  {
    imgPath: require("../../assets/calendarView.png"),
    label: "Calendar View",
  },
  {
    imgPath: require("../../assets/settingsView.png"),
    label: "Settings View",
  },
  {
    imgPath: require("../../assets/popupView.png"),
    label: "Popup View",
  },
];

export const heroContent = [
  {
    content: "Your One-Stop Shop For Thriving At UBC",
  },
  {
    content: "By Students, For Students",
  },
];

export const callToActionText = "Learn More";

export const disclaimer = `UBCStudentHub.ca and UBC Workday Side By Side Calendar are independent resources and not affiliated with the University of British Columbia in any way.`;

export const learnMoreContent = {
  title: "Workday Side By Side Calendar Extension",
  body: "The Workday Side By Side Calendar Extension is a browser extension that boasts many features including but not limited to, calendar previews and better calendar views for your courses, hiding that ugly profile picture, autofilling those annoying prompts and sharing worklists with your friends! It can be downloaded from the Chrome Web Store and you can join our Discord for more information and updates. Links to both are below.",
};
