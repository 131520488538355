export enum ResearchComponents {
  Grades = "Grades",
  RMP = "Rate My Professor Insights",
  Prerequisites = "Prerequisites",
  Reviews = "Reviews",
  WebInsights = "Web Insights",
}

export const componentMap: Record<string, ResearchComponents> = {
  Grades: ResearchComponents.Grades,
  "Rate My Professor Insights": ResearchComponents.RMP,
  Prerequisites: ResearchComponents.Prerequisites,
  Reviews: ResearchComponents.Reviews,
  "Web Insights": ResearchComponents.WebInsights,
};

export interface Position {
  controls: number;
  reviews: number;
  prerequisites: number;
  grades: number;
  rateMyProfessor: number;
  webInsights: number;
}

export const DRAG_TYPE = "RESEARCH_CARD";

export const PositionDefault: Position = {
  controls: 0,
  reviews: 1,
  prerequisites: 2,
  grades: 3,
  rateMyProfessor: 4,
  webInsights: 5,
};
