import React, { useState } from "react";
import Panel from "src/components/Panel";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { ILink, ISocialPost } from "@shared/SocialTypes";
import { IUserProfile } from "@shared/UserTypes";
import { emptyLink, submitPost, validatePost } from "./NewPostFormHelper";
import { Add } from "@mui/icons-material";
import LinkForm from "./LinkForm";
import { useFirebaseAuth } from "src/containers/AuthPages/Firebase";
import { generateId } from "src/utils";

interface INewPostProps {
  onCancel: Function;
  onSuccess: Function;
}

const NewPostForm = ({ onCancel, onSuccess }: INewPostProps) => {
  const { user } = useFirebaseAuth();
  const [postTitle, setPostTitle] = useState<string>("");
  const [postBody, setPostBody] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [links, setLinks] = useState<ILink[]>([]);
  const [submitButtonPressed, setSubmitButtonPressed] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleOnCancel = () => {
    setPostTitle("");
    setPostBody("");
    setTags([]);
    setLinks([]);
    setSubmitButtonPressed(false);
    setErrorMessage("");
    setSuccessMessage("");
    onCancel();
  };

  const handleOnSubmit = () => {
    try {
      if (user == null) {
        return;
      }
      let curTime = new Date();

      setSubmitButtonPressed(true);
      let post: ISocialPost = {
        Id: generateId(),
        title: postTitle,
        description: postBody,
        tags: tags,
        links: links,
        UserId: user.uid,
        time: curTime.toISOString(),
        comments: [],
        likedUserIds: [],
      };
      validatePost(post);
      setErrorMessage("");

      submitPost(post);
      setSuccessMessage("Post successful!  Please refresh the page.");
    } catch (err) {
      if (err instanceof Error) setErrorMessage(err.message);
    }
  };

  return (
    <Panel>
      <Typography variant="h3">New Post</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
        <TextField
          sx={{ marginBottom: "20px" }}
          required
          error={submitButtonPressed && postTitle.length == 0}
          label="Title"
          value={postTitle}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPostTitle(event.target.value);
          }}
          id="standard-basic"
          variant="filled"
        />

        <TextField
          sx={{ marginBottom: "20px" }}
          required
          error={submitButtonPressed && postBody.length == 0}
          multiline
          minRows={3}
          maxRows={5}
          label="Body"
          value={postBody}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPostBody(event.target.value);
          }}
          id="standard-basic"
          variant="filled"
        />

        <div className="LinkInputContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Links:</Typography>

            <IconButton
              color="primary"
              disabled={links.length >= 3} // move to const or smth
              onClick={() => {
                setLinks((prevLinks) => [...prevLinks, emptyLink]);
              }}
            >
              <Add />
            </IconButton>
          </div>
          {links.map((link, index) => {
            return (
              <LinkForm
                links={links}
                setLinks={setLinks}
                index={index}
                submitButtonPressed={submitButtonPressed}
              />
            );
          })}
        </div>
        <div className="FooterContainer">
          <Button
            sx={{ maxWidth: "70px", height: "35px" }}
            variant="contained"
            onClick={() => {
              handleOnSubmit();
            }}
            disabled={successMessage.length > 0}
          >
            Post
          </Button>
          <Button
            sx={{ maxWidth: "70px", height: "35px", marginRight: "10px" }}
            variant="text"
            onClick={() => {
              handleOnCancel();
            }}
            disabled={successMessage.length > 0}
          >
            Cancel
          </Button>
          {errorMessage.length >= 0 && (
            <p className="ErrorMessage">{errorMessage}</p>
          )}
          {successMessage.length >= 0 && (
            <p className="SuccessMessage">{successMessage}</p>
          )}
        </div>
      </Box>
    </Panel>
  );
};

export default NewPostForm;
