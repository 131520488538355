import React, { useEffect, useState } from "react";
import { IReview, IUserVote, ReviewSortFilter } from "./ReviewTypes";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import NewReviewForm from "./NewReviewForm/NewReviewForm";
import { getReviews, updateReviewVote } from "./reviewsHelper";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import ReviewsSkeleton from "./ReviewsSkeleton";

interface ReviewsProps {
  selectedCourse: string | null;
  showNewReview: boolean;
  reviewSortFilter: ReviewSortFilter;
}

/*
  Reviews component is a Card that displays all reviews for a selected course. 
  Adding new reviews and sorting reviews handled in top bar. Sorting criteria
  passes down to Reviews component as a prop.
 */
function Reviews({
  selectedCourse,
  showNewReview,
  reviewSortFilter,
}: ReviewsProps) {
  const [showDefaultMessage, setShowDefaultMessage] = useState<boolean>(false);
  const [activeReviews, setActiveReviews] = useState<IReview[]>([]);
  const [userVotes, setUserVotes] = useState<IUserVote>({});

  // Function to handle voting on reviews
  const handleVote = async (
    reviewId: string,
    userId: string,
    voteType: 1 | -1
  ) => {
    // 1 for upvote, -1 for downvote
    try {
      // Check if user already voted and if it matches the current vote type
      if (userVotes[reviewId] === voteType) {
        return; // Do nothing if already voted the same way
      }

      // Helper to update database and reflect changes in UI
      const updatedReview = await updateReviewVote(reviewId, userId, voteType);
      setActiveReviews((prevReviews) =>
        prevReviews.map((review) =>
          review.reviewId === reviewId ? updatedReview : review
        )
      );
      setUserVotes({ ...userVotes, [reviewId]: voteType }); // Update user vote state
    } catch (error) {
      console.error("Error voting on review:", error);
    }
  };
  useEffect(() => {
    if (selectedCourse) {
      // Get reviews from database
      getReviews(selectedCourse, reviewSortFilter).then((reviews) => {
        setActiveReviews(reviews);
        setShowDefaultMessage(reviews.length === 0);
      });
    }
  }, [selectedCourse, reviewSortFilter]);
  return (
    <React.Fragment>
      {selectedCourse ? (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1 }}>
            {showNewReview && <NewReviewForm />}
            {showDefaultMessage && (
              <Typography
                variant="h5"
                align="center"
                sx={{ paddingBottom: "25%" }}
              >
                No Reviews For That Class yet! <br></br>Maybe you should be the
                first to write one?
              </Typography>
            )}
            {!showDefaultMessage &&
              activeReviews.map((review) => (
                <Card
                  key={review.reviewId}
                  id={review.reviewId}
                  variant="outlined"
                  sx={{
                    ml: "2%",
                    mr: "2%",
                    mb: "3%",
                    minHeight: "30%",
                    maxWidth: "95%",
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        alt={review.userName}
                        src={review.userProfilePicture}
                      />
                    }
                    title={
                      <Stack
                        sx={{
                          display: "flex",
                          gap: "2%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        direction={"row"}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold" }}
                          align="center"
                        >
                          {`${review.professor} - ${review.term} ${review.year}`}
                        </Typography>
                        <Chip label={`${review.rating} / 5`} color="primary" />
                      </Stack>
                    }
                  />
                  <CardContent>
                    <Typography align="left">{review.comments}</Typography>
                  </CardContent>
                  <CardActions
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Stack
                      direction="row"
                      sx={{ gap: 1, alignItems: "center" }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleVote(review.reviewId, review.userId, 1);
                        }}
                      >
                        <ThumbUp />
                      </Button>
                      <Typography>{review.upvotes}</Typography>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleVote(review.reviewId, review.userId, -1);
                        }}
                      >
                        <ThumbDown />
                      </Button>
                      <Typography>{review.downvotes}</Typography>
                    </Stack>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Posted by: {review.userName} -{" "}
                        {new Date(review.datePosted).toLocaleDateString()}
                      </Typography>
                    </Box>
                  </CardActions>
                </Card>
              ))}
          </Box>
        </Box>
      ) : (
        <ReviewsSkeleton />
      )}
    </React.Fragment>
  );
}

export default Reviews;
