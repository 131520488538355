import React from "react";
import { IconButton, TextField } from "@mui/material";
import { ILink } from "@shared/SocialTypes";
import "./NewPostForm.css";
import { Remove } from "@mui/icons-material";
interface ILinkFormProps {
  links: ILink[];
  setLinks: (links: ILink[]) => void;
  index: number;
  submitButtonPressed: boolean;
}

const LinkForm = ({
  links,
  setLinks,
  index,
  submitButtonPressed,
}: ILinkFormProps) => {
  const handleDisplayTextChange = (newDisplayText: string) => {
    let newLinks = [...links];
    newLinks[index] = { displayText: newDisplayText, url: newLinks[index].url };
    setLinks(newLinks);
  };

  const handleUrlChange = (newUrl: string) => {
    let newLinks = [...links];
    newLinks[index] = { displayText: newLinks[index].displayText, url: newUrl };
    setLinks(newLinks);
  };

  const handleRemoveLink = (removeIndex: number) => {
    let newLinks = [...links];
    newLinks = newLinks.filter((link, index) => index != removeIndex);
    setLinks(newLinks);
  };

  return (
    <div className="LinkForm">
      <IconButton
        color="primary"
        onClick={() => {
          handleRemoveLink(index);
        }}
      >
        <Remove />
      </IconButton>
      <TextField
        sx={{ marginLeft: "10px" }}
        required
        error={submitButtonPressed && links[index].displayText.length == 0}
        size="small"
        label="Display Text"
        value={links[index].displayText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleDisplayTextChange(event.target.value);
        }}
        id={`link-${index}-displaytext`}
        variant="filled"
      />
      <TextField
        sx={{ marginLeft: "10px" }}
        required
        error={submitButtonPressed && links[index].url.length == 0}
        size="small"
        label={`Url`}
        value={links[index].url}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleUrlChange(event.target.value);
        }}
        id={`link-${index}-url`}
        variant="filled"
      />
    </div>
  );
};

export default LinkForm;
