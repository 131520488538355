import { ISocialPost, ISocialPostProps } from "@shared/SocialTypes";
import { IUserProfile } from "@shared/UserTypes";
import axios from "axios";
import { env } from "src";

export const USER_NAME_MIN_CHARS = 4;
export const USER_NAME_MAX_CHARS = 30;

export const FULL_NAME_MIN_CHARS = 4;
export const FULL_NAME_MAX_CHARS = 30;

export const GetProfileData = async (userId: string) => {
  try {
    const req = await axios.get(
      `${env.REACT_APP_BACKEND_BASE_URL}/api/users/id/${userId}`
    );

    if (req.status == 204) {
      throw "user data not found";
    } else {
      return req.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const GetPostsForUser = async (userId: string) => {
  try {
    const req = await axios.get(
      `${env.REACT_APP_BACKEND_BASE_URL}/api/social/userPosts/${userId}`
    );
    return req.data;
  } catch (err) {
    console.error(err);
  }
};

export const GetRecentPosts = async () => {
  try {
    const req = await axios.get(
      `${env.REACT_APP_BACKEND_BASE_URL}/api/social/posts/recent`
    );
    return req.data;
  } catch (err) {
    console.error(err);
  }
};

export const GetPostsByUsers = async (userIds: string[]) => {
  try {
    if (userIds.length == 0) {
      return [];
    }
    let reqBody = { followingUserIdList: userIds };
    const req = await axios.post(
      `${env.REACT_APP_BACKEND_BASE_URL}/api/social/posts/following`,
      reqBody
    );
    return req.data;
  } catch (err) {
    console.error(err);
  }
};

export const UpdateProperties = async (
  userInfo: IUserProfile,
  propertyNames: (keyof IUserProfile)[]
) => {
  try {
    let properties: { [key: string]: any } = {};
    propertyNames.forEach((property) => {
      if (userInfo.hasOwnProperty(property)) {
        properties[property] = userInfo[property];
      }
    });

    let reqBody = {
      UserId: userInfo.UserId,
      properties: properties,
    };

    await axios.post(
      `${env.REACT_APP_BACKEND_BASE_URL}/api/users/update`,
      reqBody
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const saveUserProfile = async (userInfo: IUserProfile) => {
  try {
    let fieldNames: (keyof IUserProfile)[] = [
      "userName",
      "fullName",
      "picture",
      "major",
      "year",
      "about",
    ];
    await UpdateProperties(userInfo, fieldNames);
  } catch {
    throw new Error("Failed to save user profile");
  }
};

export const validateUserProfile = (userInfo: IUserProfile) => {
  if (
    userInfo.userName.length < USER_NAME_MIN_CHARS ||
    userInfo.userName.length > USER_NAME_MAX_CHARS
  ) {
    throw new Error(
      `Username must be between ${USER_NAME_MIN_CHARS}- ${USER_NAME_MAX_CHARS} characters`
    );
  }

  if (userInfo.year != null && (userInfo.year <= 0 || userInfo.year > 8)) {
    throw new Error(`Year must be between 1-8`);
  }

  if (
    userInfo.fullName != null &&
    (userInfo.fullName.length < FULL_NAME_MIN_CHARS ||
      userInfo.fullName.length > FULL_NAME_MAX_CHARS)
  ) {
    throw new Error(
      `Full name must be between ${FULL_NAME_MIN_CHARS}- ${FULL_NAME_MAX_CHARS} characters`
    );
  }
};
