import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { useState } from "react";
import MobileMenu from "./MobileMenu";
import { useFirebaseAuth } from "../AuthPages/Firebase";

const pages = ["Calendar", "Directions", "Social", "Research"];

interface IProps {
  setShowSettingsPopup: (data: boolean) => void;
}

function TopBar({ setShowSettingsPopup }: IProps) {
  const { userProfile, signOutFB } = useFirebaseAuth();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenSettingsPopup = () => {
    setShowSettingsPopup(true);
    setAnchorElUser(null);
  };

  const handleOpenSocialProfile = () => {
    setAnchorElUser(null);
    window.location.replace(`/profile/${userProfile?.UserId}`);
  };

  return (
    <AppBar position="static" sx={{ padding: "0px 20px" }}>
      <Toolbar
        disableGutters
        sx={{ justifyContent: { xs: "space-between", md: "auto" } }}
      >
        <Typography
          variant="h6"
          noWrap
          component={Link}
          to={`/Home`}
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          UBC Student Hub
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              key={page}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              to={`/${page.toLowerCase()}`}
            >
              {page}
            </Button>
          ))}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt={userProfile?.userName ?? ""}
                src={userProfile?.picture ?? "/static/images/avatar/2.jpg"}
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {userProfile === null ? (
              <MenuItem key={"googleLogIn"} onClick={handleCloseUserMenu}>
                {/* <div onClick={() => window.location.replace("/signin")}>
                  Sign in
                </div> */}
                <div>Sign in coming soon...</div>
              </MenuItem>
            ) : (
              [
                <MenuItem key={"UserName"} disabled>
                  <Typography textAlign="center">
                    Welcome, {userProfile.userName}
                  </Typography>
                </MenuItem>,
                <MenuItem key={"UserProfile"} onClick={handleOpenSocialProfile}>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>,
                <MenuItem key={"UserSetting"} onClick={handleOpenSettingsPopup}>
                  <Typography textAlign="center">Settings</Typography>
                </MenuItem>,
                <MenuItem
                  key={"UserLogout"}
                  onClick={() => {
                    signOutFB();
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Log Out</Typography>
                </MenuItem>,
              ]
            )}
          </Menu>
        </Box>
        <MobileMenu />
      </Toolbar>
    </AppBar>
  );
}
export default TopBar;
