export interface IDirectionSection {
  code: string;
  name: string;
  instructors: string[];
  startTime: string;
  endTime: string;
  location: string;
}

export interface ISectionPair {
  startSection: IDirectionSection;
  endSection: IDirectionSection;
  startLocation: rawLocationData;
  endLocation: rawLocationData;
  directions: Directions | null
}

export interface rawLocationData {
  address: string;
  fullname: string;
  placeID: string;
  lat: number;
  long: number;
}

export const rawLocationDataBaseCase = {
  address: "",
  fullname: "",
  placeID: "",
  lat: 0,
  long: 0,
};

export interface Step {
  distance: {
    text: string;
    value: number;
  };
  duration: {
    text: string;
    value: number;
  };
  end_location: {
    lat: number;
    lng: number;
  };
  html_instructions: string;
  polyline: {
    points: string;
  };
  start_location: {
    lat: number;
    lng: number;
  };
  travel_mode: string;
  maneuver?: string;
}

export interface Directions {
  polyline: string;
  duration: string;
  distance: string;
  walkingSteps: Step[];
}

export const DirectionsBaseCase = {
  routes: [
    {
      overview_polyline: {
        points: "",
      },
      legs: [
        {
          steps: [],
          duration: {
            text: "",
          },
          distance: {
            text: "",
          },
        },
      ],
    },
  ],
}