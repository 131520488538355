import { Button, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import NewPostForm from "src/components/NewPostForm/NewPostForm";
import Panel from "src/components/Panel";
import PostFeed, { PostFeedMode } from "../Profile/PostFeed";
import UserCard from "src/components/UserProfile/UserCard";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useFirebaseAuth } from "../AuthPages/Firebase";
import UserSearch from "./UserSearch";
interface ISocialProps {}

const Social = ({}: ISocialProps) => {
  const { userProfile } = useFirebaseAuth();
  const [showNewPost, setShowNewPost] = useState<boolean>(false);
  const [postTabIndex, setPostTabIndex] = useState<string>("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setPostTabIndex(newValue);
  };

  return (
    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "min(95vw, 1600px)",
          minHeight: "92vh",
          marginTop: "20px",
        }}
      >
        <Grid container spacing={2} className="ProfilePage">
          <Grid item xs={12} sm={12} md={4}>
            <Button
              startIcon={<Add />}
              sx={{ marginBottom: "15px" }}
              variant="contained"
              disabled={userProfile == null}
              onClick={() => {
                setShowNewPost(true);
              }}
            >
              New Post
            </Button>
            {userProfile ? (
              <UserCard userData={userProfile} />
            ) : (
              <div
                style={{
                  marginLeft: "20%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <a
                  style={{ marginRight: "5px" }}
                  href={`${window.location.origin}/signin`}
                >
                  Sign in
                </a>
                <div> for a better experience!</div>
              </div>
            )}
            <div style={{ marginTop: "0.75rem" }}>
              <Panel className="SearchContainer">
                <Typography variant="h3">Search</Typography>
                <UserSearch></UserSearch>
              </Panel>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            {showNewPost && (
              <NewPostForm
                onCancel={() => {
                  setShowNewPost(false);
                }}
                onSuccess={() => {}}
              />
            )}
            <Panel>
              <Typography variant="h3">Feed</Typography>
              <TabContext value={postTabIndex}>
                <TabList centered onChange={handleChange}>
                  <Tab label="Following" value="0" /> {/* update to recent */}
                  <Tab label="Recent" value="1" />
                </TabList>
                <TabPanel value="0">
                  <PostFeed
                    userProfile={userProfile}
                    mode={PostFeedMode.FOLLOWING}
                  />
                </TabPanel>
                <TabPanel value="1">
                  <PostFeed
                    userProfile={userProfile}
                    mode={PostFeedMode.RECENT}
                  />
                </TabPanel>
              </TabContext>
            </Panel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Social;
