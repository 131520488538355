import { ImperativePanelHandle } from "react-resizable-panels";
import EditLayoutContext from "./EditLayoutContext";
import { useContext } from "react";

interface PanelRef {
  current: ImperativePanelHandle | null;
}

interface PanelRefs {
  leftMainPanelref: PanelRef;
  rightMainPanelref: PanelRef;
  panel0ref: PanelRef;
  panel1ref: PanelRef;
  panel2ref: PanelRef;
  panel3ref: PanelRef;
  panel4ref: PanelRef;
  panel5ref: PanelRef;
  panel6ref: PanelRef;
}
const useSavedPanelLayout = (panelRefs: PanelRefs, resetKey: boolean) => {
  const { setResetKey } = useContext(EditLayoutContext);
  if (resetKey) {
    console.log("resetting");
    panelRefs.leftMainPanelref.current?.resize(50);
    panelRefs.rightMainPanelref.current?.resize(50);
    panelRefs.panel0ref.current?.resize(15);
    panelRefs.panel1ref.current?.resize(43);
    panelRefs.panel2ref.current?.resize(43);
    panelRefs.panel3ref.current?.resize(33);
    panelRefs.panel4ref.current?.resize(33);
    panelRefs.panel5ref.current?.resize(33);
    setResetKey(false);
    return;
  }
  // Okay long story short, I forgot what this code does because I forgot to finish the feature and no longer remember what I was going for here
  // I believe this is a remenent of the old layout manager that had the mini view, since the react-resize-panels save them selves in local storage but back then the actual
  // UI was not saved but now that the UI uses the resize panels and there is no mini view this code should no longer needed
  // Regardless, enabling it breaks things so I'm commenting it out. When I am 100% sure it is no longer needed I will remove it
  // let main = null;
  // let left = null;
  // let right = null;

  // const defaultLayouts = {
  //   centerPath: '{"defaultSize":50},{"defaultSize":50}',
  //   rightPath: '{"defaultSize":33},{"defaultSize":33},{"defaultSize":33}',
  //   leftPath: '{"defaultSize":15},{"defaultSize":42},{"defaultSize":42}',
  // };

  // const localStorageKeys = {
  //   main: "react-resizable-panels:mainContainer",
  //   left: "react-resizable-panels:leftContainer",
  //   right: "react-resizable-panels:rightContainer",
  // };

  // const savedMainLayout = localStorage.getItem(localStorageKeys.main);
  // const savedLeftLayout = localStorage.getItem(localStorageKeys.left);
  // const savedRightLayout = localStorage.getItem(localStorageKeys.right);

  // if (savedMainLayout && savedLeftLayout && savedRightLayout) {
  //   main = JSON.parse(savedMainLayout);
  //   left = JSON.parse(savedLeftLayout);
  //   right = JSON.parse(savedRightLayout);
  // }

  // if (main && left && right) {
    // panelRefs.leftMainPanelref.current?.resize(
    //   main[defaultLayouts.centerPath].layout[0]
    // );
    // panelRefs.rightMainPanelref.current?.resize(
    //   main[defaultLayouts.centerPath].layout[1]
    // );
    // panelRefs.panel0ref.current?.resize(
    //   left[defaultLayouts.leftPath].layout[0]
    // );
    // panelRefs.panel1ref.current?.resize(
    //   left[defaultLayouts.leftPath].layout[1]
    // );
    // panelRefs.panel2ref.current?.resize(
    //   left[defaultLayouts.leftPath].layout[2]
    // );
    // panelRefs.panel3ref.current?.resize(
    //   right[defaultLayouts.rightPath].layout[0]
    // );
    // panelRefs.panel4ref.current?.resize(
    //   right[defaultLayouts.rightPath].layout[1]
    // );
    // panelRefs.panel5ref.current?.resize(
    //   right[defaultLayouts.rightPath].layout[2]
    // );
  //}
};

export default useSavedPanelLayout;
