import {
    AdvancedMarker,
    InfoWindow,
    useAdvancedMarkerRef,
  } from "@vis.gl/react-google-maps";
  import { useCallback, useState } from "react";
  import { rawLocationData } from "src/containers/Direction/Direction.types";
  
  interface IProps {
    locationData: rawLocationData;
  }
  
  export const SectionPanelMapMarker = ({locationData }: IProps) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
  
    const [infoWindowShown, setInfoWindowShown] = useState(false);
  
    const handleMarkerClick = useCallback(
      () => setInfoWindowShown((isShown) => !isShown),
      []
    );
  
    // if the maps api closes the infowindow, we have to synchronize our state
    const handleClose = useCallback(() => setInfoWindowShown(false), []);
  
    const position = {
      lat: locationData.lat,
      lng: locationData.long,
    };
  
    return (
      <>
        <AdvancedMarker
          ref={markerRef}
          position={position}
          onClick={handleMarkerClick}
        />
  
        {infoWindowShown && (
          <InfoWindow anchor={marker} onClose={handleClose}>
            <h2>{locationData.fullname}</h2>
            <p>{locationData.address}</p>
          </InfoWindow>
        )}
      </>
    );
  };
  