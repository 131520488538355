import { ISectionData, Term } from "../../../App.types"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IDirectionSection, ISectionPair } from "../Direction.types";
import { theme } from "src/Theme";
import { makeSectionPairs, sortSections } from "../directionsUtil";

interface IProps {
  sections: ISectionData[];
  selectedSections: IDirectionSection[];
  setSelectedSections: (data: IDirectionSection[]) => void;
  setSectionPairs: (data: ISectionPair[] | null) => void;
  setActiveSectionPair: (data: ISectionPair | null) => void;
  setExpandedDirectionsCard: (data: string | null) => void;
  dialogVariant?: boolean;
}
const WORKLISTS = [0, 1, 2, 3];
const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri"];

const SectionsTopBar = ({
  sections,
  selectedSections,
  setSelectedSections,
  setSectionPairs,
  setActiveSectionPair,
  setExpandedDirectionsCard,
  dialogVariant = false,
}: IProps) => {
  const [selectedWorklist, setSelectedWorklist] = useState<number>(0);
  const [selectedWeekday, setSelectedWeekday] = useState<string>("Mon");
  const [selectedTerm, setSelectedTerm] = useState<Term>(Term.One)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleWorklist = (
    event: React.MouseEvent<HTMLElement>,
    newWorklist: number
  ) => {
    setSelectedWorklist(newWorklist);
  };

  const handleTerm = (event: React.MouseEvent<HTMLElement>, newTerm: Term) => {
    setSelectedTerm(newTerm);
  };

  const handleWeekday = (
    event: React.MouseEvent<HTMLElement>,
    newWeekday: string
  ) => {
    setSelectedWeekday(newWeekday);
  };


  function handleButtonClick() {
    makeSectionPairs(selectedSections, setSectionPairs);
    setActiveSectionPair(null);
  }

  useEffect(() => {
    let newSelectedSections: IDirectionSection[] = [];
    for (let section of sections) {
      if (
        (section.terms.has(selectedTerm)) &&
        section.worklistNumber === selectedWorklist
      ) {
        for (let detail of section.sectionDetails) {
          if (detail.days.includes(selectedWeekday)) {
            newSelectedSections.push({
              code: section.code,
              name: section.name,
              instructors: section.instructors,
              startTime: detail.startTime,
              endTime: detail.endTime,
              location: detail.location || "",
            });
            break;
          }
        }
      }
    }
    newSelectedSections = newSelectedSections.sort();

    const sortedSections = sortSections(newSelectedSections);
    setSelectedSections(sortedSections);
    setActiveSectionPair(null);
    setSectionPairs(null);
    setExpandedDirectionsCard(null);
  }, [
    sections,
    selectedWorklist,
    selectedWeekday,
    selectedTerm,
  ]);

  return (
    <>
      {(!isMobile || dialogVariant) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: dialogVariant ? "column" : "",
            justifyContent: dialogVariant ? "left" : "center",
            alignItems: dialogVariant ? "left" : "center",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            gap: dialogVariant ? "10px" : "",
          }}
        >
          {/* {!dialogVariant && (
            <Typography sx={{ paddingLeft: "3%" }}>Session:</Typography>
          )}
          <FormControl size="small">
            <InputLabel id="session-select-label">Session</InputLabel>
            <Select
              labelId="session-select-label"
              id="session-select-label"
              value={selectedSession}
              label="Session"
              onChange={handleChange}
              sx={{ marginLeft: "5px" }}
            >
              {Object.values(Session).map((session) => (
                <MenuItem key={session} value={session}>
                  {session}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {!dialogVariant && (
            <Typography sx={{ marginLeft: "3%", marginRight: "5px" }}>
              Worklist:
            </Typography>
          )}
          <ToggleButtonGroup
            value={selectedWorklist}
            exclusive
            onChange={handleWorklist}
            aria-label="text alignment"
            size="small"
          >
            {WORKLISTS.map((worklist) => (
              <ToggleButton
                value={worklist}
                key={`worklist#${worklist}`}
                sx={{ width: "50px" }}
              >
                {dialogVariant ? `Worklist ${worklist}` : worklist}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {!dialogVariant && (
            <Typography sx={{ marginLeft: "3%", marginRight: "5px" }}>
              Term:
            </Typography>
          )}
          <ToggleButtonGroup
            value={selectedTerm}
            exclusive
            onChange={handleTerm}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton
              value={Term.One}
              key="TermOne"
              sx={{ width: "50px" }}
            >
              {dialogVariant ? "Term 1" : 1}
            </ToggleButton>
            <ToggleButton
              value={Term.Two}
              key="TermTwo"
              sx={{ width: "50px" }}
            >
              {dialogVariant ? "Term 2" : 2}
            </ToggleButton>
          </ToggleButtonGroup>
          {!dialogVariant && (
            <Typography sx={{ marginLeft: "3%", marginRight: "5px" }}>
              Day:
            </Typography>
          )}
          <ToggleButtonGroup
            value={selectedWeekday}
            exclusive
            onChange={handleWeekday}
            aria-label="text alignment"
            size="small"
          >
            {WEEKDAYS.map((day) => (
              <ToggleButton
                value={day}
                key={`weekday#${day}`}
                sx={{ width: "50px" }}
              >
                {day}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Button
            variant="contained"
            //disabled={!user}
            onClick={() => {
              handleButtonClick();
            }}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginLeft: "3%",
              marginRight: "3%",
            }}
          >
            Update Map
          </Button>
        </Box>
      )}
    </>
  );
};

export default SectionsTopBar;
