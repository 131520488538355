import axios from "axios";

export const handleSearchUsers = async (searchText: string) => {
  try {
    let resp = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/searchName/${searchText}`
    );
    return resp.data;
  } catch (err) {
    throw new Error("Error searching for users");
  }
};
