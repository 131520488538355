import { Card, Typography } from "@mui/material";

interface LayoutPlaceholderProps {
  name: string;
  backgroundColor: string;
  isOver: boolean;
}
function LayoutPlaceholder({
  name,
  backgroundColor,
  isOver,
}: LayoutPlaceholderProps) {
  return (
    <Card
      sx={{
        backgroundColor,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        cursor: "grab",
      }}
    >
      <Typography sx={{ color: "#fff", fontSize: "2rem" }}>
        {isOver ? "Release to drop" : name}
      </Typography>
    </Card>
  );
}

export default LayoutPlaceholder;
