import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ReviewSortFilter, ReviewSortFilterMap } from "./ReviewTypes";

interface ReviewSortSelectorProps {
  selectedFilter: ReviewSortFilter;
  setSelectedFilter: (filter: ReviewSortFilter) => void;
}
function ReviewSortSelector({
  selectedFilter,
  setSelectedFilter,
}: ReviewSortSelectorProps) {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedFilter(ReviewSortFilterMap[event.target.value]);
  };

  return (
    <FormControl size="small" sx={{ ml: "5%" }}>
      <InputLabel id="select-label">Sort Review</InputLabel>
      <Select
        labelId="select-label"
        id="select-visualization"
        value={selectedFilter}
        label="Select Visualization"
        onChange={(event) => handleChange(event)}
        color="primary"
        fullWidth
      >
        {Object.values(ReviewSortFilter).map((filter) => (
          <MenuItem key={filter} value={filter}>
            {filter}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ReviewSortSelector;
