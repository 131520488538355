import "./Calendar.css"
import { ISectionData, SectionDetail } from '../../../App.types';

export const EventTemplate = (props: any) => {
    let section: ISectionData = props.Section
    let detail: SectionDetail = props.Detail

    const getSectionCodeString = (str: string): string => {
        //CPSC_V 436-N_101   
        //CODE: first 4,  _V or _O for UBCV vs UBCO,  
        let code = str.substring(0,4)
        let rest = str.substring(6)
        
        return code + rest
    }

    const getLocationString = (str: string): string => {
        let building = str.substring(0, str.indexOf("-"))
        let room = str.substring(str.lastIndexOf(" "))
        return building + room
    }

    return (
        <div className='CalendarEvent' style={{backgroundColor: section.color} }>
            <div className='CalendarEventCourseCode'> 
                {getSectionCodeString(section.code)}
            </div>
            <div className='CalendarEventLocation'> 
                {getLocationString(detail.location  || "")}
            </div>
        </div>
    )
}