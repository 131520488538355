import { Box, useMediaQuery } from "@mui/material";
import Controls from "./ControlPanel/Controls";
import { useContext, useEffect, useRef, useState } from "react";
import { Position, ResearchComponents } from "./ResearchTypes";
import { theme } from "src/Theme";
import MobileCarousel from "./Components/MobileCarousel";
import InsightComponent from "./Components/InsightComponent";
import PositionContext from "./Contexts/PositionContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragDropContainer from "./Components/DragDropContainer";
import EditLayoutContext from "./Contexts/EditLayoutContext";
import LayoutEditor from "./ControlPanel/LayoutEditor";
import { ImperativePanelHandle } from "react-resizable-panels";
import useSavedPanelLayout from "./Contexts/LayoutHook";
/*
How To Add Insight Components:
1. Adjust grid layout to fit new component (Planned: UI for users to choose which components to view)
2. Add new component to ResearchComponents enum in ResearchTypes.ts
3. ResearchCard takes in a title, whether it is expandable, and `focusComponent` to set the focused component.
4. Add new component to ResearchCard in Research.tsx. If you want it to be viewable in full screen, 
   set `expandable` to true and add a new case in the conditional rendering at the bottom of the file 
   with a `FocusedResearchCard`.
5. Course selection is handled by the `selectedCourse` state. If your component requires a course to be 
   selected, make sure to check if `selectedCourse` is not null before rendering.
6. If your component requires additional filters with a selector in the top bar, add a new state and pass 
   it down to the component.
 */

function Research() {
  const [focusComponent, setFocusComponent] =
    useState<ResearchComponents | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { position, setPositionState } = useContext(PositionContext);
  const { editLayout, resetKey } = useContext(EditLayoutContext);
  const panelRefs = {
    leftMainPanelref: useRef<ImperativePanelHandle>(null),
    rightMainPanelref: useRef<ImperativePanelHandle>(null),
    panel0ref: useRef<ImperativePanelHandle>(null),
    panel1ref: useRef<ImperativePanelHandle>(null),
    panel2ref: useRef<ImperativePanelHandle>(null),
    panel3ref: useRef<ImperativePanelHandle>(null),
    panel4ref: useRef<ImperativePanelHandle>(null),
    panel5ref: useRef<ImperativePanelHandle>(null),
    panel6ref: useRef<ImperativePanelHandle>(null),
  };

  useSavedPanelLayout(panelRefs, resetKey);
  // Persist layout positions and sizing. Using local storage because react-resize-panels uses it by default anyways
  useEffect(() => {
    const savedPosition = localStorage.getItem("ResearchLayoutPositions");
    if (savedPosition) {
      setPositionState(JSON.parse(savedPosition) as Position);
    }
  }, []);

  const getComponent = (index: number) => {
    const entries = Object.entries(position);
    const foundComponentName = entries.find(([key, value]) => value === index);

    if (!foundComponentName) {
      return { component: <></>, name: "" };
    }

    const [name, _] = foundComponentName;

    switch (name) {
      case "controls":
        return {
          component: (
            <Controls
              setSelectedCourse={setSelectedCourse}
              selectedCourse={selectedCourse}
            />
          ),
          name: "Controls",
        };
      case "reviews":
        return {
          component: (
            <InsightComponent
              component={ResearchComponents.Reviews}
              selectedCourse={selectedCourse}
              setFocusComponent={setFocusComponent}
              isFocusComponent={focusComponent === ResearchComponents.Reviews}
            />
          ),
          name: "Reviews",
        };
      case "prerequisites":
        return {
          component: (
            <InsightComponent
              component={ResearchComponents.Prerequisites}
              selectedCourse={selectedCourse}
              setFocusComponent={setFocusComponent}
              isFocusComponent={
                focusComponent === ResearchComponents.Prerequisites
              }
            />
          ),
          name: "Prerequisites",
        };
      case "grades":
        return {
          component: (
            <InsightComponent
              component={ResearchComponents.Grades}
              selectedCourse={selectedCourse}
              setFocusComponent={setFocusComponent}
              isFocusComponent={focusComponent === ResearchComponents.Grades}
            />
          ),
          name: "Grades",
        };
      case "rateMyProfessor":
        return {
          component: (
            <InsightComponent
              component={ResearchComponents.RMP}
              selectedCourse={selectedCourse}
              setFocusComponent={setFocusComponent}
              isFocusComponent={focusComponent === ResearchComponents.RMP}
            />
          ),
          name: "Rate My Professor Insights",
        };
      case "webInsights":
        return {
          component: (
            <InsightComponent
              component={ResearchComponents.WebInsights}
              selectedCourse={selectedCourse}
              setFocusComponent={setFocusComponent}
              isFocusComponent={
                focusComponent === ResearchComponents.WebInsights
              }
            />
          ),
          name: "Web Insights",
        };
      default:
        return { component: <></>, name: "" };
    }
  };

  return (
    <Box sx={{ height: "92vh" }}>
      {!isMobile ? (
        <DndProvider backend={HTML5Backend}>
          <DragDropContainer getComponent={getComponent} refs={panelRefs} />
          {editLayout && <LayoutEditor />}
        </DndProvider>
      ) : (
        <MobileCarousel
          setFocusComponent={setFocusComponent}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
        />
      )}
    </Box>
  );
}

export default Research;
