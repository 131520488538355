import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ISectionData } from "../../App.types";

interface IProps {
  handleClose: () => void;
  sections: ISectionData[];
  setSections: (data: ISectionData[]) => void;
}

const SyncPopup = ({ handleClose, sections, setSections }: IProps) => {
  // const [isSyncSuccess, setIsSyncSuccess] = useState<boolean>(false);
  // const [savedSections, setSavedSections] = useState<ISectionData[]>([]);

  // useEffect(() => {
  //   getUserSections(user.id).then((sections) => {
  //     console.log(sections);
  //     setSavedSections(sections);
  //   });
  // }, []);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      {/* {isSaveSuccess && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Save Success!
          </Alert>
        )} */}
      <DialogTitle id="alert-dialog-title">Sync Worklists</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyncPopup;
