import { Box } from "@mui/material";
import { Map } from "@vis.gl/react-google-maps";
import { ISectionPair } from "../Direction.types";
import { MarkerWithPopup } from "./MarkerWithPopup";
import { Polyline } from "./Polyline";
interface IProps {
  activeSectionPair: ISectionPair | null;
  sectionPairs: ISectionPair[] | null;
}

const MainMap = ({ activeSectionPair, sectionPairs }: IProps) => {
  const position = { lat: 49.26418305490913, lng: -123.24540463749351 };

  return (
    <Box height={"92vh"}>
      <Map
        defaultCenter={position}
        defaultZoom={14}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        mapId={"d7274cc4edd93b1b"}
      >
        {sectionPairs && sectionPairs.map((pair) => (
          <>
            <MarkerWithPopup
              sectionData={pair.startSection}
              locationData={pair.startLocation}
            />
            <MarkerWithPopup
              sectionData={pair.endSection}
              locationData={pair.endLocation}
            />
          </>
        ))}
        {activeSectionPair && activeSectionPair.directions && (
          <Polyline
            strokeWeight={10}
            strokeColor={"#1976D2"}
            encodedPath={activeSectionPair.directions.polyline}
          />
        )}
      </Map>
    </Box>
  );
};

export default MainMap;
