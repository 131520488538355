import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SyntheticEvent, useContext } from "react";
import { ALL_COURSES } from "src/assets/courselist";
import { theme } from "src/Theme";
import EditLayoutContext from "../Contexts/EditLayoutContext";

interface ControlsProps {
  setSelectedCourse: (course: string | null) => void;
  selectedCourse: string | null;
}

/*
  Controls component is a Card that contains the course selection dropdown.
  The dropdown is used to select the course for which the user wants to insights.
  Planned: Settings Dialog for user customizing the UI
 */
function Controls({ setSelectedCourse, selectedCourse }: ControlsProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setEditLayout } = useContext(EditLayoutContext);
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          sx={{ alignItems: "center", marginTop: "-2%" }}
        >
          <Typography variant="h5">Select Course</Typography>
          <Autocomplete
            disablePortal
            id="course-code-autocomplete"
            options={ALL_COURSES}
            sx={{ width: "50%" }}
            value={selectedCourse}
            onChange={(event: SyntheticEvent, newValue: string | null) => {
              setSelectedCourse(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="filled" label="Course" />
            )}
          />
          {!isMobile && (
            <Button
              variant="contained"
              sx={{ ml: "5%" }}
              onClick={() => {
                setEditLayout(true);
              }}
            >
              Edit Layout
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Controls;
