import { useContext } from "react";
import EditLayoutContext from "../Contexts/EditLayoutContext";
import { DraggableComponent } from "./DraggableComponent";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

interface DragDropContainerProps {
  getComponent: (index: number) => { component: JSX.Element; name: string };
  refs: any;
}
export const DragDropContainer = ({
  getComponent,
  refs,
}: DragDropContainerProps) => {
  const { editLayout } = useContext(EditLayoutContext);

  const centerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <PanelGroup
      autoSaveId="mainContainer"
      direction="horizontal"
      style={{
        ...centerStyle,
        width: "100%",
        height: "92vh",
      }}
    >
      <Panel
        defaultSize={50}
        style={{ ...centerStyle, height: "100%" }}
        ref={refs.leftMainPanelref}
      >
        <PanelGroup autoSaveId="leftContainer" direction="vertical">
          <Panel
            defaultSize={13}
            style={{ ...centerStyle, margin: "1%" }}
            ref={refs.panel0ref}
          >
            <DraggableComponent component={getComponent(0)} index={0} />
          </Panel>
          {editLayout && <PanelResizeHandle />}
          <Panel
            defaultSize={43}
            style={{ ...centerStyle, margin: "1%" }}
            ref={refs.panel1ref}
          >
            <DraggableComponent component={getComponent(1)} index={1} />
          </Panel>
          {editLayout && <PanelResizeHandle />}
          <Panel
            defaultSize={43}
            style={{ ...centerStyle, margin: "1%" }}
            ref={refs.panel2ref}
          >
            <DraggableComponent component={getComponent(2)} index={2} />
          </Panel>
        </PanelGroup>
      </Panel>
      {editLayout && (
        <PanelResizeHandle
          style={{
            width: "0.3rem",
            height: "100%",
          }}
        />
      )}
      <Panel
        defaultSize={50}
        style={{ ...centerStyle, height: "100%" }}
        ref={refs.rightMainPanelref}
      >
        <PanelGroup autoSaveId="rightContainer" direction="vertical">
          <Panel
            defaultSize={33}
            style={{ ...centerStyle, margin: "1%" }}
            ref={refs.panel3ref}
          >
            <DraggableComponent component={getComponent(3)} index={3} />
          </Panel>
          {editLayout && <PanelResizeHandle />}
          <Panel
            defaultSize={33}
            style={{ ...centerStyle, margin: "1%" }}
            ref={refs.panel4ref}
          >
            <DraggableComponent component={getComponent(4)} index={4} />
          </Panel>
          {editLayout && <PanelResizeHandle />}
          <Panel
            defaultSize={33}
            style={{ ...centerStyle, margin: "1%" }}
            ref={refs.panel5ref}
          >
            <DraggableComponent component={getComponent(5)} index={5} />
          </Panel>
        </PanelGroup>
      </Panel>
    </PanelGroup>
  );
};

export default DragDropContainer;
