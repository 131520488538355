import { ISocialPost } from "@shared/SocialTypes";
import axios from "axios";
import { env } from "src";

export const emptyLink = {
  displayText: "",
  url: "",
};

export const validatePost = (post: ISocialPost) => {
  if (post.title.length == 0) {
    throw new Error("The post's title cannot be empty.");
  }

  if (post.description.length == 0) {
    throw new Error("The post's body cannot be empty.");
  }

  post.links.forEach((link) => {
    if (link.displayText.length == 0) {
      throw new Error("A link's display text cannot be empty.");
    }
    if (link.url.length == 0) {
      throw new Error("A link's url cannot be empty.");
    }
  });
};

export const submitPost = async (post: ISocialPost) => {
  try {
    let url = `${env.REACT_APP_BACKEND_BASE_URL}/api/social/userPosts`;
    let resp = await axios.post(url, post);
    return "Post Successful";
  } catch (err) {
    console.error(err);
    throw `Error Posting: ${err}`;
  }
};
