import { Link } from "@mui/material";
import { Map, APIProvider } from "@vis.gl/react-google-maps";
import { ICalendarEvent } from "../../../App.types";
import { useEffect, useState } from "react";
import { rawLocationData } from "src/containers/Direction/Direction.types";
import { SectionPanelMapMarker } from "./SectionPanelMapMarker";
import { getRawLocationData } from "src/containers/Direction/directionsUtil";

interface IProps {
  selectedEvent: ICalendarEvent | undefined;
}

const SectionLocation = ({ selectedEvent }: IProps) => {
  const [selectedEventLocation, setSelectedEventLocation] =
    useState<rawLocationData | null>();

  const mapPosition = { lat: 49.26418305490913, lng: -123.24540463749351 };

  useEffect(() => {
    fetchLocationData();
  }, [selectedEvent]);

  const fetchLocationData = async () => {
    if (!selectedEvent) {
      return;
    }

    if (selectedEvent.Detail.location) {
      let locationData = await getRawLocationData(
        selectedEvent.Detail.location
      );
      console.log(locationData);
      setSelectedEventLocation(locationData);
    }
  };

  const getLocationDisplay = (location?: string): JSX.Element => {
    let locationText = `${
      selectedEventLocation?.fullname
    } ${location?.substring(location.lastIndexOf("-") + 1)}`;

    let building = location?.substring(0, location?.indexOf("-"));
    let room = location?.substring(location?.lastIndexOf(" ") + 1);
    let strForRoomUrl = (building + "-" + room).toLowerCase();

    return (
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}>
        <div className="SectionDataHeader">Location:</div>
        <Link
          href={`https://learningspaces.ubc.ca/classrooms/${strForRoomUrl}`}
          className="SectionData"
          target="_blank"
          rel="noopener"
        >
          {locationText}
        </Link>
        <div className="MapContainer">
          <Map
            defaultCenter={mapPosition}
            defaultZoom={14}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
            mapId={"d7274cc4edd93b1b"}
            style={{ width: "calc(25vw - 1.5rem)" }}
          >
            {location && selectedEventLocation && (
              <SectionPanelMapMarker locationData={selectedEventLocation} />
            )}
          </Map>
        </div>
      </APIProvider>
    );
  };

  return getLocationDisplay(
    selectedEvent?.Detail.location ? selectedEvent.Detail.location : undefined
  );
};

export default SectionLocation;
