import {
  Avatar,
  Button,
  Card,
  Chip,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { DarkBackgroundColor } from "../../Theme";
import {
  ILink,
  IPostComment,
  IPostCommentProps,
  ISocialPostProps,
} from "@shared/SocialTypes";
import "./SocialPost.css";
import { Comment, Reply, ThumbUpAlt } from "@mui/icons-material";
import { useState } from "react";
import { useFirebaseAuth } from "src/containers/AuthPages/Firebase";
import { generateId } from "src/utils";
import {
  SendComment,
  UpdateLikedUserList,
  validateComment,
} from "./SocialPostHelper";
import CommentsContainer from "./CommentsContainer";

const SocialPost = ({
  Id,
  title,
  description,
  links,
  tags,
  time,
  likedUserIds,
  comments,
  user,
}: ISocialPostProps) => {
  const loggedInUser = useFirebaseAuth().user;
  const userProfile = useFirebaseAuth().userProfile;

  const [showCommentInput, setShowCommentInput] = useState<boolean>(false);
  const [commentInput, setCommentInput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [localComments, setLocalComments] = useState<IPostCommentProps[]>(
    comments ?? []
  );
  const [localLikedUserIds, setLocalLikedUserIds] = useState<string[]>(
    likedUserIds ?? []
  );

  const handleSendComment = async () => {
    if (loggedInUser?.uid == null) {
      setErrorMessage("Must be logged in to reply");
      return;
    }
    try {
      let curTime = new Date();

      let comment: IPostComment = {
        UserId: loggedInUser.uid,
        CommentId: generateId(),
        message: commentInput,
        time: curTime.toISOString(),
      };
      validateComment(comment);
      await SendComment(comment, Id, user.UserId);
      let localComment: IPostCommentProps = {
        ...comment,
        userName: userProfile?.userName ?? "Unknown User",
        userPicture: userProfile?.picture ?? "",
      };
      let newComments = [...localComments, localComment];
      setLocalComments(newComments);
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
      }
      console.error(err);
    }
  };

  const handleLikeClick = async () => {
    if (userProfile == null) return;
    let newLikedUserIds = [...localLikedUserIds];
    try {
      if (localLikedUserIds.includes(userProfile.UserId)) {
        newLikedUserIds = newLikedUserIds.filter(
          (id) => id != userProfile.UserId
        );
      } else {
        newLikedUserIds = [...newLikedUserIds, userProfile.UserId];
      }
      await UpdateLikedUserList(newLikedUserIds, Id, user.UserId);
      setLocalLikedUserIds(newLikedUserIds);
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
      }
      console.error(err);
    }
  };

  return (
    <Card
      sx={{
        padding: "0.5rem",
        borderRadius: "8px",
        marginBottom: "0.75rem",
        textAlign: "Left",
        border: `2px solid ${DarkBackgroundColor}`,
        maxHeight: "600px",
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="PostItemsContainer">
        <div className="UserInfoContainer">
          <Avatar
            alt="User Profile Picture"
            src={user.picture ?? "/static/images/avatar/2.jpg"}
            sx={{
              width: "70px",
              height: "70px",
              marginBottom: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.replace(`/profile/${user?.UserId}`);
            }}
          />
          <Typography variant="h6">
            {user.userName ?? "Unknown User"}
          </Typography>
          <Typography variant="body2">{time.split("T")[0]}</Typography>
        </div>
        <div className="PostTitle">
          <Typography
            sx={{
              marginBottom: "10px",
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
            variant="h5"
          >
            {title}
          </Typography>
          <Typography variant="body1">{description}</Typography>
          {tags.map((tag) => {
            return <Chip label={tag} />;
          })}
          <div className="LinksContainer">
            {links.map((link: ILink) => {
              return (
                <a target="_blank" rel="noopener noreferrer" href={link.url}>
                  {link.displayText}
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className="PostFooter">
        <Divider sx={{ marginBottom: "5px" }} />
        {showCommentInput ? (
          <div className="CommentInputContainer">
            <TextField
              sx={{ width: "75%" }}
              multiline
              maxRows={3}
              required
              label="Comment"
              size="small"
              value={commentInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCommentInput(event.target.value);
              }}
              id="commentTextField"
              variant="filled"
            />
            <Button
              size="small"
              variant="text"
              onClick={() => {
                setShowCommentInput(false);
              }}
            >
              Cancel
            </Button>
            <Button
              startIcon={<Reply />}
              size="small"
              variant="outlined"
              disabled={commentInput.length == 0}
              onClick={() => {
                handleSendComment();
              }}
            >
              Send
            </Button>
          </div>
        ) : (
          <div className="PostButtonContainer">
            <Button
              startIcon={<ThumbUpAlt />}
              variant={
                userProfile != null &&
                localLikedUserIds.includes(userProfile.UserId)
                  ? "contained"
                  : "outlined"
              }
              onClick={handleLikeClick}
              disabled={loggedInUser?.uid == null}
            >
              {`${localLikedUserIds.length} Likes`}
            </Button>
            <Button
              startIcon={<Comment />}
              variant="text"
              onClick={() => {
                setShowCommentInput(true);
              }}
              disabled={loggedInUser?.uid == null}
            >
              {`${localComments.length} Comments`}
            </Button>
          </div>
        )}
      </div>
      <CommentsContainer comments={localComments} />
    </Card>
  );
};

export default SocialPost;
