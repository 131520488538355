import { Box, Skeleton } from "@mui/material";

/*
  Placeholder to display when selected course is null
*/
function WebInsightsSkeleton() {
  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <Box sx={{ width: "50%", height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", padding: 2 }}>
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={20} />
        </Box>
      </Box>
      <Box sx={{ width: "50%", height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", padding: 2 }}>
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={20} />
        </Box>
      </Box>
    </Box>
  );
}

export default WebInsightsSkeleton;
