import { IUserProfile } from "@shared/UserTypes";
import axios from "axios";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  User,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

interface IFirebaseContextProps {
  user: User | null;
  userProfile: IUserProfile | null;
  setUserProfile: (profile: IUserProfile) => void;
  signInFB: (email: string, password: string) => Promise<User | null>;
  signOutFB: () => void;
  registerUserWithEmail: (
    email: string,
    password: string
  ) => Promise<User | null>;
}

const FirebaseContext = createContext<IFirebaseContextProps>({
  user: null,
  userProfile: null,
  setUserProfile: (profile: IUserProfile) => {},
  signInFB: async (email: string, password: string) => null,
  signOutFB: () => Promise<void>,
  registerUserWithEmail: async (email: string, password: string) => null,
});

export const useFirebaseAuth = () => useContext(FirebaseContext);

interface IFirebaseProviderProps {
  children: ReactNode;
}

export const FirebaseProvider = ({ children }: IFirebaseProviderProps) => {
  const [user, setUser] = useState<User | null>(null); // todo merge this state with IUser.
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      try {
        const updateUserProfile = async () => {
          let userProfileReq = await axios.get(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/id/${user?.uid}`
          );
          setUserProfile(userProfileReq.data);
        };
        updateUserProfile();
      } catch (err) {
        console.error(err);
      }
    } else {
      setUserProfile(null);
    }
  }, [user]);

  const signOutFB = async () => {
    await signOut(auth);
  };

  const signInFB = async (email: string, password: string) => {
    let userCred = await signInWithEmailAndPassword(auth, email, password);
    return userCred.user;
  };

  const registerUserWithEmail = async (email: string, password: string) => {
    let userCred = await createUserWithEmailAndPassword(auth, email, password);
    return userCred.user;
  };

  return (
    <FirebaseContext.Provider
      value={{
        user,
        userProfile,
        setUserProfile,
        signInFB,
        signOutFB,
        registerUserWithEmail,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
