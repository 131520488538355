/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import "./App.css";
import TopBar from "./containers/TopBar/TopBar";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home/Home";
import CalendarContainer from "./containers/CalendarContainer/CalendarContainer";
import Direction from "./containers/Direction/Direction";
import Social from "./containers/Social/Social";
import { ISectionData } from "./App.types";
import SettingsPopup from "./containers/SettingsPopup/SettingsPopup";
import SyncPopup from "./containers/SyncPopup/SyncPopup";
import Profile from "./containers/Profile/Profile";
import { addSections, getUserSections } from "./utils";
import {
  FirebaseProvider,
  useFirebaseAuth,
} from "./containers/AuthPages/Firebase";
import SigninPage from "./containers/AuthPages/SigninPage";
import RegisterPage from "./containers/AuthPages/RegisterPage";
import Research from "./containers/ResearchContainer/Research";
import {
  serializeSetReplacer,
  serializeSetReviver,
} from "./storage/sectionsStorage";
import { PositionProvider } from "./containers/ResearchContainer/Contexts/PositionContext";
import { EditLayoutProvider } from "./containers/ResearchContainer/Contexts/EditLayoutContext";
function App() {
  const { user } = useFirebaseAuth();
  const [sections, setSections] = useState<ISectionData[]>([]);
  const [showSettingsPopup, setShowSettingsPopup] = useState<boolean>(false);
  const [showSyncPopup, setShowSyncPopup] = useState<boolean>(false);

  useEffect(() => {
    if (user && sections.length == 0) {
      getUserSections(user.uid).then((returnedSections) => {
        if (sections === returnedSections) {
          return;
        } else {
          addSections(
            user.uid,
            sections,
            () => {},
            () => {}
          );
        }
      });
    } else if (user) {
      getUserSections(user.uid).then((sections) => {
        setSections(sections);
      });
    }
    localStorage.setItem("user", JSON.stringify(user, serializeSetReplacer));
  }, [user, sections]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== "https://wd10.myworkday.com") return;

      if (event.data && event.data.sections) {
        if (user) {
          setSections(event.data.sections);
        } else {
          localStorage.setItem(
            "sections",
            JSON.stringify(event.data.sections, serializeSetReplacer)
          );
          setSections(event.data.sections);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const savedSections = localStorage.getItem("sections");
    if (savedSections && sections.length === 0 && !user) {
      console.log("setting sections");
      try {
        setSections(JSON.parse(savedSections, serializeSetReviver));
      } catch {
        alert("Please try reopening window from the extension again");
        localStorage.setItem(
          "sections",
          JSON.stringify([], serializeSetReplacer)
        );
      }
    }
  }, []);

  return (
    <div className="App">
      {showSettingsPopup && user && (
        <SettingsPopup
          handleClose={() => setShowSettingsPopup(false)}
          user={user}
          sections={sections}
          setSections={setSections}
        />
      )}
      {showSyncPopup && user && (
        <SyncPopup
          handleClose={() => setShowSyncPopup(false)}
          sections={sections}
          setSections={setSections}
        />
      )}
      <TopBar setShowSettingsPopup={setShowSettingsPopup} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/Calendar"
          element={
            <CalendarContainer
              sections={sections}
              setShowSyncPopup={setShowSyncPopup}
            />
          }
        />
        <Route path="/Directions" element={<Direction sections={sections} />} />
        <Route path="/Social" element={<Social />} />
        <Route path="/Profile/:userId" element={<Profile />} />
        <Route
          path="/Research"
          element={
            <PositionProvider>
              <EditLayoutProvider>
                <Research />
              </EditLayoutProvider>
            </PositionProvider>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
