import axios from "axios";
import fetchProfRating, {
  RMPData,
} from "src/containers/CalendarContainer/SectionInfoPanel/helpers/RateMyProfHelpers";

export const getProfData = async (
  selectedCourse: string
): Promise<RMPData[]> => {
  const isVancouver = selectedCourse.split(" ")[0].split("_")[1] === "V";
  const professors = await getRawTeachingStaffData(selectedCourse, isVancouver);

  const ratings = await Promise.all(
    Array.from(professors).map(async (prof) => {
      const rating = await fetchProfRating(prof, isVancouver);
      return rating;
    })
  );

  const filteredRatings = ratings.filter(
    (rating) => rating !== null
  ) as RMPData[]; // Need to cast or else TS will complain

  return filteredRatings;
};

// Get the professor list for a course from the UBC Academic API. Will probably need to be updated when our own wrapper is ready
const getRawTeachingStaffData = async (
  courseCode: string,
  isVancouver: boolean
): Promise<Set<string>> => {
  // Can't use the existing parseCourseParts function because this format is slightly different
  const code = courseCode.split(" ")[0];
  const courseNumber = courseCode.split(" ")[1].split("-")[0];

  const rawData = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/api/research/academic-api/${code}/${courseNumber}/${isVancouver}`
  );

  const rawTeachingStaff = rawData.data.pageItems;
  const staffNames = new Set<string>();

  for (const pageItem of rawTeachingStaff) {
    if (!pageItem.teachingAssignments) continue;

    for (const rawStaffMember of pageItem.teachingAssignments) {
      for (const worker of rawStaffMember.identifiers) {
        if (!worker.worker) break;
        staffNames.add(
          `${worker.worker.personNames[0].givenName} ${worker.worker.personNames[0].familyName}`
        );
      }
    }
  }
  return staffNames;
};
