import React, { ReactNode } from "react";
import { Box, Button, Card, CardProps, Typography } from "@mui/material";
import { ResearchComponents } from "src/containers/ResearchContainer/ResearchTypes";
import { Close } from "@mui/icons-material";

interface FocusedResearchCardProps extends CardProps {
  children: ReactNode;
  title: string;
  setFocusComponent: (data: ResearchComponents | null) => void;
  topbarChildren?: ReactNode;
}

/*
 FocusResearchCard component is a full screen version of ResearchCard that contains a 
 title, children, and optional topbarChildren. For more information, see ResearchCard.
 */
const FocusedResearchCard: React.FC<FocusedResearchCardProps> = ({
  children,
  title,
  setFocusComponent,
  topbarChildren,
}) => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 999,
    }}
  >
    <Card
      sx={{
        height: "95%",
        width: "95%",
        marginLeft: "10px",
        marginTop: "10px",
      }}
    >
      <Box sx={{ margin: "10px", display: "grid", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" color="primary">
            {title}
          </Typography>
          {topbarChildren && (
            <Box sx={{ display: "grid", justifyContent: "center" }}>
              {topbarChildren}
            </Box>
          )}
          <Button
            onClick={() => {
              setFocusComponent(null);
            }}
          >
            <Close color="primary" />
          </Button>
        </Box>
        <div style={{ overflow: "auto" }}>{children}</div>
      </Box>
    </Card>
  </Box>
);

export default FocusedResearchCard;
