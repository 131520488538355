import { IPostCommentProps } from "@shared/SocialTypes";
import React from "react";
import "./SocialPost.css";
import { Avatar, Typography } from "@mui/material";

interface ICommentsContainerProps {
  comments: IPostCommentProps[];
}

const CommentsContainer = ({ comments }: ICommentsContainerProps) => {
  return (
    <div className="CommentListContainer">
      {comments.map((comment) => {
        return (
          <div className="CommentContainer">
            <Avatar
              sx={{ height: "40px", width: "40px", marginRight: "10px" }}
              alt={comment.userName}
              src={comment.userPicture ?? ""}
            ></Avatar>
            <div key={comment.CommentId} className="CommentBox">
              <Typography variant="h6">{comment.userName}</Typography>
              <Typography sx={{ marginLeft: "10px" }} variant="body1">
                {comment.message}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommentsContainer;
