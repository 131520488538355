import {
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  Collapse,
} from "@mui/material";
import { useEffect, useState } from "react";
import HeroImage from "./HeroImage";
import LearnMore from "./LearnMore";
import {
  callToActionText,
  disclaimer,
  heroContent,
} from "./LandingPageContent";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loadAnimation, setLoadAnimation] = useState(false);

  const handleLearnMoreClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const targetElement = document.getElementById("learn-more-section");
    if (!targetElement) return;
    targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    setLoadAnimation(true);
  }, []);

  return (
    <div style={{ background: "linear-gradient(#1976d2, #fff)" }}>
      <Grid
        container
        columns={2}
        alignItems={isMobile ? "flex-start" : "center"}
        justifyContent={isMobile ? "center" : ""}
        height={"100vh"}
      >
        <Grid item sm={2} md={1}>
          <Collapse
            in={loadAnimation}
            {...(loadAnimation ? { timeout: 1000 } : {})}
          >
            <div style={{ padding: "50px", justifyContent: "center" }}>
              <Typography variant="h2" color="white">
                {heroContent[0].content}
              </Typography>
              <Typography variant="h6" color="white" style={{justifyContent: "center", display: "grid"}}>
                {heroContent[1].content}
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={handleLearnMoreClick}
                sx={{ mt: 2 }}
              >
                {callToActionText}
              </Button>
            </div>
          </Collapse>
        </Grid>
        <Grid item sm={0} md={1}>
          <HeroImage />
        </Grid>
      </Grid>
      <div
        id="learn-more-section"
        style={{
          height: "100vh",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          columns={1}
          alignItems="center"
          justifyContent="center"
          alignContent={"center"}
          height={"95vh"}
        >
          <Grid item sm={1} md={1}>
            <LearnMore />
          </Grid>
        </Grid>

        <Typography
          sx={{ fontSize: isSmallMobile ? "0.6rem" : "" }}
          variant="body1"
          color="#000"
        >
          {disclaimer}
        </Typography>
      </div>
    </div>
  );
};

export default Home;
