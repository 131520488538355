import React, { ReactNode } from "react";
import {
  Box,
  Card,
  CardProps,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

interface PostsCardProps extends CardProps {
  children: ReactNode;
  title: string;
  icon: ReactNode;
}

/*
  Post Card component is a Card current used for displaying Reddit and Google posts.
  The header takes an icon and a Title, with the children displayed below
 */
const PostsCard: React.FC<PostsCardProps> = ({ children, title, icon }) => (
  <Card sx={{ width: "50%", height: "100%", overflowY: "auto" }}>
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {icon}
      <Typography variant="h5">{title}</Typography>
    </Stack>
    <Divider />
    {children}
  </Card>
);

export default PostsCard;
