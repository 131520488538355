import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { useState } from "react";
import { ISectionData } from "../../App.types";
import { addSections, verifySections } from "../../utils";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import { User } from "firebase/auth";

interface IProps {
  handleClose: () => void;
  user: User;
  sections: ISectionData[];
  setSections: (data: ISectionData[]) => void;
}

const SettingsPopup = ({
  handleClose,
  user,
  sections,
  setSections,
}: IProps) => {
  const [changedSettings, setChangedSettings] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [newSections, setNewSections] = useState<ISectionData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState<boolean>(false);

  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target?.result as string);
        if (!verifySections(data)) {
          alert(
            "Error in file imported, make sure you export from the latest extension version. Is error persist, contact support."
          );
          return;
        }
        setFileName(file.name);
        setChangedSettings(true);
        setNewSections(data);
      } catch (error) {
        console.error("Failed to parse JSON file", error);
      }
    };
    reader.readAsText(file);
  };

  const handleSaveSuccess = () => {
    setIsSaveSuccess(true);
    setIsLoading(false);
    setChangedSettings(false);
    setNewSections([]);
    setFileName("");
    setSections(newSections);
    setTimeout(function () {
      setIsSaveSuccess(false);
    }, 3000);
  };

  const handleSaveFail = () => {
    setIsLoading(false);
    alert("Whoops, something went wrong");
  };

  const handleSave = () => {
    if (fileName !== "" && newSections.length !== 0) {
      setIsLoading(true);
      addSections(user.uid, newSections, handleSaveSuccess, handleSaveFail);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      {isSaveSuccess && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          Save Success!
        </Alert>
      )}
      <DialogTitle id="alert-dialog-title">Settings</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>Import Worklists</Typography>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUpload />}
          >
            Upload File
            <input
              type="file"
              accept="application/json"
              onChange={handleImport}
              hidden
            />
          </Button>
          {fileName && <Typography color={"grey"}>{fileName}</Typography>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {isLoading ? (
          <LoadingButton loading variant="contained">
            Submit
          </LoadingButton>
        ) : (
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={!changedSettings}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SettingsPopup;
