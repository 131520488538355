import { IReview, ReviewSortFilter } from "./ReviewTypes";
import axios from "axios";
import { env } from "src";

// Function to get reviews for a specific course from the backend
export const getReviews = async (
  course: string,
  reviewSortFilter: ReviewSortFilter
): Promise<IReview[] | []> => {

  try {
    let url = `${env.REACT_APP_BACKEND_BASE_URL}/api/course-reviews/search-by-course/?course=${course}`;
    let resp = await axios.get(url);
    if (Array.isArray(resp.data) && resp.data.length === 0) {
      return [];
    }

    const reviews = resp.data as IReview[];
    switch (reviewSortFilter) {
      case ReviewSortFilter.Newest:
        reviews.sort((a, b) =>
          new Date(a.datePosted) > new Date(b.datePosted) ? -1 : 1
        ); // Newest first
        break;
      case ReviewSortFilter.Oldest:
        reviews.sort((a, b) =>
          new Date(a.datePosted) < new Date(b.datePosted) ? -1 : 1
        ); // Oldest first
        break;
      case ReviewSortFilter.MostHelpful:
        reviews.sort(
          (a, b) => b.upvotes + b.downvotes - (a.upvotes + a.downvotes)
        ); // Most helpful first (upvotes - downvotes)
        break;
      case ReviewSortFilter.LeastHelpful:
        reviews.sort(
          (a, b) => a.upvotes + a.downvotes - (b.upvotes + b.downvotes)
        ); // Least helpful first (upvotes - downvotes)
        break;
      default:
        // Handle unexpected sort filter values (optional)
        console.warn(`Unknown sort filter: ${reviewSortFilter}`);
        break;
    }

    return reviews;
  } catch (err) {
    console.error(err);
    throw `Error submitting: ${err}`;
  }
};

// Called when user upvotes or downvotes a review
export async function updateReviewVote(
  reviewId: string,
  userId: string,
  vote: 1 | -1 // 1 for upvote, -1 for downvote
) {
  let url = `${env.REACT_APP_BACKEND_BASE_URL}/api/course-reviews/votes/${reviewId}/${userId}/${vote}`;
  let resp = await axios.post(url);

  return resp.data;
}
