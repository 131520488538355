import { useState } from "react";
import { GradesVisualizations } from "../GradesInsights/GradesTypes";
import { ResearchComponents } from "../ResearchTypes";
import { ReviewSortFilter } from "../ReviewsInsights/ReviewTypes";
import { RedditSortFilter } from "../WebInsights/WebInsightsTypes";
import ResearchCard from "./ResearchCard";
import GradesViewSelector from "../GradesInsights/GradesViewSelector";
import GradesGraph from "../GradesInsights/GradesGraph";
import Prerequisites from "../PreReqInsights/Prerequisites";
import { Box, Button } from "@mui/material";
import ReviewSortSelector from "../ReviewsInsights/ReviewSortSelector";
import Reviews from "../ReviewsInsights/Reviews";
import RedditSortSelector from "../WebInsights/RedditSortSelector";
import WebInsights from "../WebInsights/WebInsights";
import RMPInsights from "../RMPInsights/RMPInsights";
import { Add } from "@mui/icons-material";

// Props all insight components will receive
interface InsightComponentProps {
  component: ResearchComponents;
  selectedCourse: string | null;
  setFocusComponent: (component: ResearchComponents | null) => void;
  isFocusComponent: boolean;
  isMobile?: boolean;
}

function InsightComponent({
  component,
  selectedCourse,
  setFocusComponent,
  isFocusComponent,
  isMobile,
}: InsightComponentProps) {
  const [gradesVisualization, setGradesVisualization] =
    useState<GradesVisualizations>(GradesVisualizations.Distributions);
  const [reviewSortFilter, setReviewSortFilter] = useState<ReviewSortFilter>(
    ReviewSortFilter.Newest
  );
  const [showNewReview, setShowNewReview] = useState<boolean>(false);
  const [redditSortFilter, setRedditSortFilter] = useState<RedditSortFilter>(
    RedditSortFilter.Newest
  );
  const renderTopBar = () => {
    switch (component) {
      case ResearchComponents.Grades:
        return (
          <GradesViewSelector
            selectedView={gradesVisualization}
            setSelectedView={setGradesVisualization}
          />
        );
      case ResearchComponents.Reviews:
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <ReviewSortSelector
              selectedFilter={reviewSortFilter}
              setSelectedFilter={setReviewSortFilter}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowNewReview(true)}
            >
              <Add fontSize="small" />
            </Button>
          </Box>
        );
      case ResearchComponents.WebInsights:
        return (
          <RedditSortSelector
            selectedFilter={redditSortFilter}
            setSelectedFilter={setRedditSortFilter}
          />
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (component) {
      case ResearchComponents.Grades:
        return (
          <GradesGraph
            selectedCourse={selectedCourse}
            selectedView={gradesVisualization}
          />
        );
      case ResearchComponents.Prerequisites:
        return <Prerequisites  selectedCourse={selectedCourse}/>;
      case ResearchComponents.Reviews:
        return (
          <Reviews
            selectedCourse={selectedCourse}
            showNewReview={showNewReview}
            reviewSortFilter={reviewSortFilter}
          />
        );
      case ResearchComponents.WebInsights:
        return (
          <WebInsights
            selectedCourse={selectedCourse}
            redditSortFilter={redditSortFilter}
          />
        );
      case ResearchComponents.RMP:
        return <RMPInsights selectedCourse={selectedCourse} />;
      default:
        return null;
    }
  };

  return (
    <ResearchCard
      title={component}
      expandable={!isMobile}
      setFocusComponent={setFocusComponent}
      isFocused={isFocusComponent}
      topbarChildren={renderTopBar()}
    >
      {renderContent()}
    </ResearchCard>
  );
}

export default InsightComponent;
