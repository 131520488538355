import { useEffect, useState } from "react";
import { generatePreReqData } from "./PreRequisitesHelpers";
import { parseCourseParts } from "src/utils";
import { PreReqNode } from "./PreRequisitesTypes";
import PreReqTree from "./PreReqTree";
import WebInsightsSkeleton from "../WebInsights/WebInsightsSkeleton";

interface PrerequisitesProps {
  selectedCourse: string | null;
}
function Prerequisites({ selectedCourse }: PrerequisitesProps) {
  const [treeData, setTreeData] = useState<PreReqNode | null>(null);

  useEffect(() => {
    if (!selectedCourse) {
      setTreeData(null);
      return;
    }
    const [courseCode, courseNum] = parseCourseParts(selectedCourse);
    generatePreReqData(courseCode, courseNum).then((data) => {
      setTreeData(data);
    });
  }, [selectedCourse]);

  return (
    <>{treeData ? <PreReqTree data={treeData} /> : <WebInsightsSkeleton />}</>
  );
}
export default Prerequisites;
