import { ICalendarEvent, ISectionData, Term } from "../../App.types"
import InfoModal from "../../components/InfoModal/InfoModal";
import "./CalendarContainer.css";
import { useState } from "react";
import CalendarSelector from "./CalendarSelector/CalendarSelector";
import Calendar from "./Calendar/Calendar";
import SectionInfoPanel from "./SectionInfoPanel/SectionInfoPanel";
import { IUserProfile } from "@shared/UserTypes";

interface IProps {
  sections: ISectionData[];
  setShowSyncPopup: (state: boolean) => void;
}

const CalendarContainer = ({ sections, setShowSyncPopup }: IProps) => {
  const [showSaveInfo, setShowSaveInfo] = useState<boolean>(false);
  const [selectedWorklist, setSelectedWorklist] = useState<number>(0);
  const [selectedTerm, setSelectedTerm] = useState<Term>(Term.One)

  const [selectedEvent, setSelectedEvent] = useState<ICalendarEvent>();

  const testSections = sections.filter((s) => {
    return s.worklistNumber === selectedWorklist && s.terms.has(selectedTerm)
  });

  return (
    <div className="CalendarFullContainer">
      {showSaveInfo && (
        <InfoModal
          title="Save Worklists"
          text="This will override your previously saved worklists, are you sure you wish to continue?"
          handleClose={() => setShowSaveInfo(false)}
          handleContinue={() => setShowSaveInfo(false)}
        />
      )}

      <div className="CalendarPanelContainer">
        <div className="CalendarContainer">
          <CalendarSelector
            setShowSyncPopup={setShowSyncPopup}
            selectedWorklist={selectedWorklist}
            setSelectedWorklist={setSelectedWorklist}
            selectedTerm={selectedTerm}
            setSelectedTerm={setSelectedTerm}
          />
          <Calendar
            sections={testSections}
            selectedTerm={selectedTerm}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        </div>
        <SectionInfoPanel
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
        />
      </div>
    </div>
  );
};

export default CalendarContainer;
