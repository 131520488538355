import { Button, Grid, MobileStepper } from "@mui/material";
import Controls from "../ControlPanel/Controls";
import { ResearchComponents } from "../ResearchTypes";
import InsightComponent from "./InsightComponent";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useState } from "react";

interface MobileCarouselProps {
  selectedCourse: string | null;
  setSelectedCourse: (course: string | null) => void;
  setFocusComponent: (component: ResearchComponents | null) => void;
}
function MobileCarousel({
  selectedCourse,
  setSelectedCourse,
  setFocusComponent,
}: MobileCarouselProps) {
  const [activeStep, setActiveStep] = useState(0);
  const MAX_STEPS = Object.keys(ResearchComponents).length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getComponent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <InsightComponent
            component={ResearchComponents.Reviews}
            selectedCourse={selectedCourse}
            setFocusComponent={setFocusComponent}
            isFocusComponent={false}
            isMobile={true}
          />
        );
      case 1:
        return (
          <InsightComponent
            component={ResearchComponents.Grades}
            selectedCourse={selectedCourse}
            setFocusComponent={setFocusComponent}
            isFocusComponent={false}
            isMobile={true}
          />
        );
      case 2:
        return (
          <InsightComponent
            component={ResearchComponents.RMP}
            selectedCourse={selectedCourse}
            setFocusComponent={setFocusComponent}
            isFocusComponent={false}
            isMobile={true}
          />
        );
      case 3:
        return (
          <InsightComponent
            component={ResearchComponents.WebInsights}
            selectedCourse={selectedCourse}
            setFocusComponent={setFocusComponent}
            isFocusComponent={false}
            isMobile={true}
          />
        );

      case 4:
        return (
          <InsightComponent
            component={ResearchComponents.Prerequisites}
            selectedCourse={selectedCourse}
            setFocusComponent={setFocusComponent}
            isFocusComponent={false}
            isMobile={true}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Grid container sx={{ p: "1%", height: "91vh" }}>
      <Grid
        item
        columns={12}
        sx={{ width: "100%", display: "grid", alignContent: "center" }}
      >
        <Controls
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: "3%", height: "70%" }}>
        {getComponent(activeStep)}
      </Grid>
      <Grid item xs={12} sx={{ mt: "3%" }}>
        <MobileStepper
          variant="dots"
          steps={MAX_STEPS}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === MAX_STEPS - 1}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Grid>
    </Grid>
  );
}

export default MobileCarousel;
