import { useEffect, useState } from "react";
import { IGradesAPIData, getGradesData, getGradesUrl } from "./GradesHelper";
import { ISectionData } from "src/App.types";
import "./GradesComponent.css";

interface IProps {
  selectedSection: ISectionData;
}

const GradesComponent = ({ selectedSection }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gradesData, setGradesData] = useState<IGradesAPIData>();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchGrades = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        let data = await getGradesData(selectedSection);
        setGradesData(data);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchGrades();
  }, [selectedSection]);

  const gradesURL = getGradesUrl(selectedSection);

  return (
    <div className="GradesComponentContainer">
      {isError ? (
        <div>Error loading grades.</div>
      ) : isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
            <div>
              <span style={{ fontWeight: "500" }}>Average (All Time): </span>
              {gradesData?.average
                ? gradesData.average.toFixed(2)
                : "unavailable"}
            </div>
            <div>
            <span style={{ fontWeight: "500" }}>Average (5 Years):  </span>
            <span> {gradesData?.averageFiveYears
              ? gradesData?.averageFiveYears.toFixed(2)
              : "unavailable"}</span>
            </div>
          <a
            className="GradesLink"
            href={gradesURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on UBC-Grades
          </a>
        </>
      )}
    </div>
  );
};

export default GradesComponent;
