import {
  Box,
  Card,
  CardContent,
  Divider,
  Paper,
  Skeleton,
  Stack,
} from "@mui/material";

function RMPSkeleton() {
  return (
    <Card sx={{ width: "100%", height: "100%", overflowY: "auto" }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "10%",
          justifyContent: "center",
          bgcolor: "background.default",
        }}
      >
        <Skeleton animation="wave" width={100} variant="rounded" />
      </Paper>
      <CardContent>
        <Stack
          direction="row"
          spacing="2"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Box
            sx={{
              width: "40%",
              pl: "5%",
              pr: "5%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton animation="wave" width={100} variant="rounded" />

            <Skeleton
              variant="circular"
              animation="wave"
              width={30}
              height={30}
            />
          </Box>
          <Box
            sx={{
              width: "40%",
              pl: "5%",
              pr: "5%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton animation="wave" width={100} variant="rounded" />

            <Skeleton
              variant="circular"
              animation="wave"
              width={30}
              height={30}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing="2"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Box
            sx={{
              width: "40%",
              pl: "5%",
              pr: "5%",
              pt: "3%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton animation="wave" width={100} variant="rounded" />

            <Skeleton
              variant="circular"
              animation="wave"
              width={30}
              height={30}
            />
          </Box>
          <Box
            sx={{
              width: "40%",
              pl: "5%",
              pr: "5%",
              pt: "3%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton animation="wave" width={100} variant="rounded" />

            <Skeleton
              variant="circular"
              animation="wave"
              width={30}
              height={30}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
export default RMPSkeleton;
