import React, { useEffect, useState } from "react";
import { GooglePost, RedditPost, RedditSortFilter } from "./WebInsightsTypes";
import { getGoogleResults, getRedditPosts } from "./WebInsightsHelper";
import { Box, Stack, Typography, Link, useMediaQuery } from "@mui/material";
import RedditIcon from "@mui/icons-material/Reddit";
import { Google } from "@mui/icons-material";
import PostsCard from "src/components/PostsCard";
import WebInsightsSkeleton from "./WebInsightsSkeleton";
import { theme } from "src/Theme";

interface WebInsightsProps {
  selectedCourse: string | null;
  redditSortFilter: RedditSortFilter;
}

/*
  WebInsights component is a container that displays Reddit and Google posts for a selected course.
  Reddit posts are sorted based on the selected filter, and Google posts are displayed in order
  of relevance. 
  To add new web insights, add a new PostsCard to the Box with the appropriate data.
  Define its type in WebInsightsTypes.ts and add a new helper to fetch its data in WebInsightsHelper.ts
 */
function WebInsights({ selectedCourse, redditSortFilter }: WebInsightsProps) {
  const [redditPosts, setRedditPosts] = useState<RedditPost[] | null>(null);
  const [googlePosts, setGooglePosts] = useState<GooglePost[] | null>(null);
  const isMediumDevice = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    if (selectedCourse) {
      getRedditPosts(selectedCourse, redditSortFilter).then(
        (data: RedditPost[]) => {
          setRedditPosts(data);
        }
      );
      getGoogleResults(selectedCourse).then((data: GooglePost[]) => {
        setGooglePosts(data);
      });
    }
  }, [selectedCourse, redditSortFilter]);
  return (
    <React.Fragment>
      {selectedCourse ? (
        <Box sx={{ height: "100%", display: "flex" }}>
          <PostsCard title="/r/UBC" icon={<RedditIcon color="primary" />}>
            {redditPosts?.map((post: RedditPost, index) => (
              <Box
                key={index}
                sx={{ display: "flex", flexDirection: "column", padding: 2 }}
              >
                <Link
                  underline="none"
                  href={post.url}
                  target="_blank"
                  variant="h6"
                >
                  {post.title}
                </Link>
                <Stack
                  direction={isMediumDevice ? "column" : "row"}
                  alignItems={isMediumDevice ? "flex-end" : "center"}
                  spacing={1}
                >
                  <Typography variant="body2" color="text.secondary">
                    {post.upvotes} upvotes
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {isMediumDevice ? "" : "• "}
                    {post.Date.toLocaleDateString()}
                  </Typography>
                </Stack>
              </Box>
            ))}
          </PostsCard>
          <PostsCard title="Google" icon={<Google color="primary" />}>
            {googlePosts?.map((post: GooglePost, index) => (
              <Box
                key={index}
                sx={{ display: "flex", flexDirection: "column", padding: 2 }}
              >
                <Link
                  underline="none"
                  href={post.link}
                  target="_blank"
                  variant="h6"
                >
                  {post.title}
                </Link>
              </Box>
            ))}
          </PostsCard>
        </Box>
      ) : (
        <WebInsightsSkeleton />
      )}
    </React.Fragment>
  );
}
export default WebInsights;
