import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { GradesVisualizations, GradesVisualizationsMap } from "./GradesTypes";

interface GradesViewSelectorProps {
  selectedView: GradesVisualizations;
  setSelectedView: (view: GradesVisualizations) => void;
}

/*
  GradesViewSelector component is a dropdown that allows the user to select the type of visualization they want to see
  The selected view is passed down to the GradesGraph component to determine which graph to display
 */
function GradesViewSelector({
  selectedView,
  setSelectedView,
}: GradesViewSelectorProps) {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedView(GradesVisualizationsMap[event.target.value]);
  };

  return (
    <FormControl fullWidth size="small" sx={{ ml: "5%" }}>
      <InputLabel id="select-label">Select Visualization</InputLabel>
      <Select
        labelId="select-label"
        id="select-visualization"
        value={selectedView}
        label="Select Visualization"
        onChange={(event) => handleChange(event)}
        color="primary"
      >
        {Object.values(GradesVisualizations).map((visualization) => (
          <MenuItem key={visualization} value={visualization}>
            {visualization}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default GradesViewSelector;
