import axios from "axios";
import {
  GooglePost,
  RawPostData,
  RedditPost,
  RedditSortFilter,
} from "./WebInsightsTypes";
import { parseCourseParts } from "src/utils";

// There's a lot of data available in the Reddit API, so lots of room for expansion here
export const getRedditPosts = async (
  selectedCourse: string,
  redditSortFilter: RedditSortFilter
): Promise<RedditPost[]> => {
  const [courseCode, courseNum] = parseCourseParts(selectedCourse);
  const rawData = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/api/research/reddit/${courseCode}/${courseNum}`
  );

  const redditPosts: RedditPost[] = [];
  rawData.data.data.children.forEach((post: RawPostData) => {
    redditPosts.push({
      title: post.data.title,
      upvotes: post.data.ups,
      url: post.data.url,
      Date: new Date(post.data.created_utc * 1000),
    });
  });

  switch (redditSortFilter) {
    case RedditSortFilter.Newest:
      redditPosts.sort((a, b) => (a.Date > b.Date ? -1 : 1));
      break;
    case RedditSortFilter.Oldest:
      redditPosts.sort((a, b) => (a.Date < b.Date ? -1 : 1));
      break;
    case RedditSortFilter.Upvotes:
      redditPosts.sort((a, b) => (a.upvotes < b.upvotes ? 1 : -1));
      break;
  }

  return redditPosts;
};

// Have to get from backend because of CORS
export const getGoogleResults = async (
  selectedCourse: string
): Promise<GooglePost[]> => {
  const [courseCode, courseNum] = parseCourseParts(selectedCourse);

  const rawData = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/api/research/google-search/${courseCode}/${courseNum}`
  );
  const googleResults: GooglePost[] = [];

  // Probably unnnecessary but makes it easier in terms of Types and expansion in the future if we fetch more data,
  // can easily expand the interface
  rawData.data.forEach((post: GooglePost) => {
    googleResults.push({
      title: post.title,
      link: post.link,
    });
  });
  return googleResults;
};
