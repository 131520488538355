import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./Theme";
import { registerLicense } from "@syncfusion/ej2-base";
import { FirebaseProvider } from "./containers/AuthPages/Firebase";

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY || "");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
export const env = process.env;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <FirebaseProvider>
          <App />
        </FirebaseProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
