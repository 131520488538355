import { fetchDirections, getRoomData } from "src/utils";
import {
  Directions,
  DirectionsBaseCase,
  IDirectionSection,
  ISectionPair,
  rawLocationData,
  rawLocationDataBaseCase,
  Step,
} from "./Direction.types";

const formatRoomCode = (roomCode: string) => {
  return roomCode.split("-")[0];
};

const getLocationdata = async (
  section: IDirectionSection
): Promise<rawLocationData> => {
  if (!section.location || section.location === "") {
    return rawLocationDataBaseCase;
  }

  const roomCode = formatRoomCode(section.location);

  const rawLocationData = await getRoomData(roomCode);

  if (!rawLocationData.placeID) {
    return rawLocationDataBaseCase;
  }

  return convertToRawLocationData(rawLocationData)
};

const convertToRawLocationData = (apiRes: any) => {
  const [lat, long] = extractLatLong(apiRes);
  return {
    address: apiRes.address,
    fullname: apiRes.fullname,
    placeID: apiRes.placeID,
    lat: lat,
    long: long,
  };
}

const extractLatLong = (data: any) => {
  const coordinates: { lat: number; lng: number } = JSON.parse(data.latLong);
  const lat: number = coordinates.lat;
  const lng: number = coordinates.lng;

  return [lat, lng];
};

export const makeSectionPairs = async (
  sections: IDirectionSection[],
  setSectionPairs: (data: ISectionPair[]) => void
) => {
    const pairs: ISectionPair[] = [];

    for (let i = 0; i < sections.length - 1; i++) {
      const startLocation = await getLocationdata(sections[i]);
      const endLocation = await getLocationdata(sections[i + 1]);
      pairs.push({
        startSection: sections[i],
        endSection: sections[i + 1],
        startLocation: startLocation,
        endLocation: endLocation,
        directions: await getDirections(startLocation, endLocation),
      });
    }
    console.log("Made Pairs ", pairs);
    setSectionPairs(pairs);
};

const getDirections = async (
  startLocation: rawLocationData,
  endLocation: rawLocationData
): Promise<Directions> => {
  let rawDirectionData = DirectionsBaseCase;

    rawDirectionData = await fetchDirections(
      startLocation.placeID,
      endLocation.placeID
    );



  console.log("Raw Direction Data: ", rawDirectionData);
  const overviewPolyline: string =
    rawDirectionData.routes[0].overview_polyline.points;
  const walkingDirections: Step[] = rawDirectionData.routes[0].legs[0].steps;
  const duration: string = rawDirectionData.routes[0].legs[0].duration.text;
  const distance: string = rawDirectionData.routes[0].legs[0].distance.text;

  const directions: Directions = {
    polyline: overviewPolyline,
    walkingSteps: walkingDirections,
    duration: duration,
    distance: distance,
  };

  return directions;
};

export const sortSections = (sections: IDirectionSection[]) => {
  return sections.sort((a: IDirectionSection, b: IDirectionSection) => {
    const [hoursA, minutesA] = a.startTime.split(":");
    const [hoursB, minutesB] = b.startTime.split(":");

    const timeA = parseInt(hoursA, 10) * 60 + parseInt(minutesA, 10);
    const timeB = parseInt(hoursB, 10) * 60 + parseInt(minutesB, 10);

    return timeA - timeB;
  });
};

//Used in calendar sidepanel
export const getRawLocationData = async (room: string): Promise<rawLocationData> => {
  let roomCode = formatRoomCode(room);
  let data = await getRoomData(roomCode);
  return convertToRawLocationData(data);

}
