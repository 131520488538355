import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { ISocialPostProps } from "@shared/SocialTypes";
import SocialPost from "src/components/SocialPost/SocialPost";
import {
  GetPostsByUsers,
  GetPostsForUser,
  GetRecentPosts,
} from "./ProfileHelper";
import { IUserProfile } from "@shared/UserTypes";

export enum PostFeedMode {
  "USER",
  "FOLLOWING",
  "RECENT",
}

interface IPostFeedProps {
  userProfile: IUserProfile | null;
  mode: PostFeedMode;
}

const PostFeed = ({ userProfile, mode }: IPostFeedProps) => {
  const [postData, setPostData] = useState<ISocialPostProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        if (userProfile && mode == PostFeedMode.USER) {
          let postDataReq = await GetPostsForUser(userProfile.UserId);
          setPostData(postDataReq);
        } else if (mode == PostFeedMode.RECENT) {
          let postDataReq = await GetRecentPosts();
          setPostData(postDataReq);
        } else if (userProfile && mode == PostFeedMode.FOLLOWING) {
          let postDataReq = await GetPostsByUsers(userProfile.followingUserIds);
          setPostData(postDataReq);
        }
      } catch (err) {
        setErrorMessage("Failed to get user post data");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, [mode]);

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : errorMessage !== "" ? (
        <div>{`Failed to get posts ${errorMessage}`}</div>
      ) : postData?.length > 0 ? (
        postData.map((post) => {
          return <SocialPost key={post.Id} {...post} />;
        })
      ) : (
        <div>No Posts</div>
      )}
    </>
  );
};

export default PostFeed;
