import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import "./Profile.css";
import { LightBackgroundColor, SecondaryColor } from "../../Theme";
import Panel from "../../components/Panel";
import { IUserProfile } from "@shared/UserTypes";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetProfileData, UpdateProperties } from "./ProfileHelper";
import PostFeed, { PostFeedMode } from "./PostFeed";
import NewPostForm from "src/components/NewPostForm/NewPostForm";
import { Add, Edit, Remove } from "@mui/icons-material";
import { useFirebaseAuth } from "../AuthPages/Firebase";
import EditProfileModal from "./EditProfileModal";

interface IProfileProps {}

const Profile = ({}: IProfileProps) => {
  const { userId } = useParams();
  const { userProfile, setUserProfile } = useFirebaseAuth();
  const isProfileOwner = userProfile?.UserId == userId;

  const [pageData, setPageData] = useState<IUserProfile>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const handleFollowUnfollow = async () => {
    try {
      if (userProfile == null || pageData == null) {
        return;
      }
      let newLoggedInUserProfile = { ...userProfile };
      let newUserData = { ...pageData };
      if (isFollowing) {
        newLoggedInUserProfile.followingUserIds =
          newLoggedInUserProfile.followingUserIds.filter(
            (id) => id != pageData.UserId
          );
        newUserData.followerUserIds = newUserData.followerUserIds.filter(
          (id) => id != userProfile.UserId
        );
      } else {
        newLoggedInUserProfile.followingUserIds = [
          ...newLoggedInUserProfile.followingUserIds,
          pageData.UserId,
        ];
        newUserData.followerUserIds = [
          ...newUserData.followerUserIds,
          userProfile.UserId,
        ];
      }
      await UpdateProperties(newLoggedInUserProfile, ["followingUserIds"]);
      await UpdateProperties(newUserData, ["followerUserIds"]);
      setPageData(newUserData);
      setUserProfile(newLoggedInUserProfile);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      GetProfileData(userId).then((userInfo) => {
        setPageData(userInfo);
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (userId) {
      let isUserFollowing = userProfile
        ? userProfile?.followingUserIds.includes(userId)
        : false;
      setIsFollowing(isUserFollowing);
    }
  }, [pageData, userProfile]);

  return (
    <>
      {pageData != null && (
        <EditProfileModal
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          userProfile={pageData}
          setUserProfile={setUserProfile}
          setProfilePageData={setPageData}
        />
      )}
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "min(95vw, 1850px)",
          minHeight: "92vh",
          backgroundColor: LightBackgroundColor,
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : pageData == null ? (
          <div>Failed to get profile data</div>
        ) : (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Grid
              sx={{ marginTop: "20px", maxWidth: "95vw" }}
              container
              spacing={2}
              className="ProfilePage"
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Panel>
                  <Avatar
                    alt=""
                    src={pageData.picture ?? "/static/images/avatar/2.jpg"}
                    sx={{
                      width: 240,
                      height: 240,
                      margin: "10px",
                    }}
                  />
                  <Typography variant="h3">{pageData.userName}</Typography>
                  <Typography variant="h6" color={SecondaryColor}>
                    {pageData.major ?? "unknown major"}
                    {" | Year: " + (pageData.year ?? "unknown")}
                  </Typography>
                  <Typography variant="body1">
                    {`${
                      pageData.followerUserIds
                        ? pageData.followerUserIds.length
                        : "0"
                    } Followers | ${
                      pageData.followingUserIds
                        ? pageData.followingUserIds.length
                        : "0"
                    } Following`}
                  </Typography>
                  {!isProfileOwner && (
                    <Button
                      startIcon={isFollowing ? <Remove /> : <Add />}
                      size="small"
                      sx={{ marginTop: "5px" }}
                      variant="contained"
                      disabled={userProfile == null}
                      onClick={() => {
                        handleFollowUnfollow();
                      }}
                    >
                      {isFollowing ? "Unfollow" : "Follow"}
                    </Button>
                  )}
                  {isProfileOwner && (
                    <Button
                      startIcon={<Edit />}
                      size="medium"
                      sx={{ marginTop: "5px" }}
                      variant="text"
                      disabled={userProfile == null}
                      onClick={() => {
                        setShowEditModal(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}

                  {/* <Typography variant="h6" color={SecondaryColor}>
                Clubs
              </Typography>
              <Typography variant="body1">None</Typography> */}
                  {/* <Typography variant="body1">UBC CSSS</Typography>
              <Typography variant="body1">Mama's Bowling Club</Typography> */}
                </Panel>
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={9}>
                <Panel>
                  <Typography variant="h3">About</Typography>
                  <Typography variant="body1">
                    {pageData.about ?? ""}
                  </Typography>
                </Panel>
                {isProfileOwner && (
                  <NewPostForm onSuccess={() => {}} onCancel={() => {}} />
                )}
                <Panel>
                  <Typography variant="h3">Posts</Typography>
                  <PostFeed userProfile={pageData} mode={PostFeedMode.USER} />
                </Panel>
              </Grid>
            </Grid>
          </div>
        )}
      </Container>
    </>
  );
};
export default Profile;
