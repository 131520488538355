import { Term } from "../../../App.types"
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import "./CalendarSelector.css";
import SyncIcon from "@mui/icons-material/Sync";
import { IUserProfile } from "@shared/UserTypes";
import { useFirebaseAuth } from "src/containers/AuthPages/Firebase";

interface IProps {
  setShowSyncPopup: (state: boolean) => void;
  selectedWorklist: number;
  setSelectedWorklist: (data: number) => void;
  selectedTerm: Term;
  setSelectedTerm: (data: Term) => void;
}

const WORKLISTS = [0, 1, 2, 3];
const TERMS = [0, 1]

const CalendarSelector = ({
  setShowSyncPopup,
  selectedWorklist,
  setSelectedWorklist,
  selectedTerm,
  setSelectedTerm,
}: IProps) => {
  const { user } = useFirebaseAuth();

  const handleChangeWorklist = (
    event: React.MouseEvent<HTMLElement>,
    newWorklist: number
  ) => {
    setSelectedWorklist(newWorklist);
  };

  const handleChangeTerm = (
    event: React.MouseEvent<HTMLElement>,
    newTerm: number
  ) => {
    setSelectedTerm(newTerm);
  };

  return (
    <div className="CalendarSelectorContainer">
      {/* <div className="CalendarSessionSelector">
        <Typography>Session:</Typography>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Session</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={session}
            label="Session"
            onChange={handleChangeSession}
          >
            {Object.values(Session).map((session) => (
              <MenuItem key={session} value={session}>
                {session}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div> */}

      <div className="CalendarSelectorGroupContainer">
        <div className="CalendarSelectorGroup">
          <Typography>Worklist:</Typography>
          <ToggleButtonGroup
            value={selectedWorklist}
            exclusive
            onChange={handleChangeWorklist}
            aria-label="text alignment"
            size="small"
          >
            {WORKLISTS.map((worklist) => (
              <ToggleButton value={worklist} key={`worklist#${worklist}`}>
                {worklist}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>

        <div className="CalendarSelectorGroup">
          <Typography>Term:</Typography>
          <ToggleButtonGroup
            value={selectedTerm}
            exclusive
            onChange={handleChangeTerm}
            aria-label="text alignment"
            size="small"
          >
            {TERMS.map((term) => (
              <ToggleButton value={term} key={`term#${selectedTerm}`}>
                {term + 1}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default CalendarSelector;
