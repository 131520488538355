import { createTheme, FilledInput } from "@mui/material";

export const LightBackgroundColor = "#f6f6f6";
export const DarkBackgroundColor = "#ededed";
export const PanelColor = "#ffffff";
export const PrimaryColor = "#1976d2";
export const SecondaryColor = "#4098ef";
export const AccentColor = "#2b2d31";
export const TextColor = "#FFFFFF";
export const ErrorColor = "#FF2222";

export const theme = createTheme({
  typography: {
    h3: {
      color: PrimaryColor,
      fontSize: "2rem",
      textAlign: "left",
      fontWeight: 600,
    },
    h5: {
      color: PrimaryColor,
      fontSize: "1.2rem",
      textAlign: "left",
      fontWeight: 600,
    },
    h6: {
      color: SecondaryColor,
      fontSize: "1rem",
      textAlign: "left",
      fontWeight: 600,
    },
    body1: {},
    caption: {
      textDecoration: "underline",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: LightBackgroundColor,
            borderRadius: "5px 5px 0px 0px",
          },
        },
      },
    },
  },
});
