import { Grid, Skeleton } from "@mui/material";

/*
  Placeholder to display when selected course is null
 */
function SkeletonChart() {
  return (
    <Grid
      container
      spacing={2}
      sx={{ display: "flex", alignItems: "baseline" }}
    >
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={70} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={90} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={110} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={130} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={140} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={150} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={150} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={140} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={130} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={110} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={90} animation="wave" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="rounded" height={70} animation="wave" />
      </Grid>
    </Grid>
  );
}

export default SkeletonChart;
