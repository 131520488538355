import { Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { handleSearchUsers } from "./SocialHelper";
import { IUserProfile } from "@shared/UserTypes";
import UserCard from "src/components/UserProfile/UserCard";

const UserSearch = () => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [usersData, setUsersData] = useState<IUserProfile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleButtonClick = async () => {
    try {
      setIsLoading(true);
      let data = await handleSearchUsers(searchInput);
      setUsersData(data);
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <TextField
          sx={{ margin: "10px", width: "100%" }}
          size="small"
          required
          label="Username"
          value={searchInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchInput(event.target.value);
          }}
          id="standard-basic"
          variant="filled"
        />
        <Button
          startIcon={<Search />}
          size="small"
          variant="text"
          onClick={handleButtonClick}
          disabled={searchInput.length == 0}
          sx={{ width: "120px", height: "30px" }}
        >
          Search
        </Button>
      </div>

      {isLoading ? (
        <CircularProgress />
      ) : usersData.length == 0 ? (
        <Typography variant="body1">No users found</Typography>
      ) : (
        usersData.map((user) => {
          return (
            <div style={{ marginBottom: "5px" }}>
              <UserCard userData={user}></UserCard>
            </div>
          );
        })
      )}
    </Stack>
  );
};

export default UserSearch;
